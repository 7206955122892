import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, IconButton, Grid } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { getCategoryByID } from '../../api/categories';
import { formatDate, formatNumber } from '../../utils';

const ProductCard = ({ image, title, price, location, date }) => {
    return (
        <Card sx={{
            display: 'flex',
            borderRadius: '15px',
            boxShadow: 'none',
            backgroundColor: '#e5f7e5',
            mb: 2
        }}>
            <Grid container alignItems="center">
                <Grid item xs={2}>
                    <Box
                        component="img"
                        sx={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '15px 0 0 15px',
                            objectFit: 'cover'
                        }}
                        alt={title}
                        src={image}
                    />
                </Grid>
                <Grid item xs={8}>
                    <CardContent>
                        <Typography variant="h6" component="div" fontWeight="bold">
                            {title}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {price} тг
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {location}, {date}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box>
                        <IconButton>
                            <FavoriteBorderIcon />
                        </IconButton>
                        <IconButton>
                            <NotificationsNoneIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

const FilterProducts = ({ data }) => {
    const [categories, setCategories] = useState({});
    const API_URL = process.env.REACT_APP_API_URL;

    const formatImageUrl = (url) => {
        if (!url) return '';
        return `${API_URL}/${url.replace(/\\/g, '/')}`;
    };

    const fetchCategoryNames = async (productList) => {
        const categoryMap = {};
        for (let product of productList) {
            if (!categoryMap[product.category_id]) {
                const categoryData = await getCategoryByID(product.category_id);
                categoryMap[product.category_id] = categoryData.name_ru;
            }
        }
        setCategories(categoryMap);
    };

    useEffect(() => {
        fetchCategoryNames(data);
    }, [data]);

    return (
        <Box sx={{ padding: 2 }}>
            {data?.map((product, index) => (
                <ProductCard
                    key={index}
                    image={formatImageUrl(product?.images[0]?.url)}
                    title={categories[product.category_id] || 'Loading...'}
                    price={formatNumber(product.price)}
                    location={product.region.NameRus}
                    date={formatDate(product.CreatedAt)}
                />
            ))}
        </Box>
    );
};

export default FilterProducts;
