// import React, { useEffect, useRef } from 'react';

// const YandexMap = ({ address, formData, setFormData }) => {
//   const mapRef = useRef(null);
//   const ymapsRef = useRef(null);
//   const placemarkRef = useRef(null);
//   // const [coordinates, setCoordinates] = useState([formData.latitude, formData.longitude]); 

//   useEffect(() => {
//     if (window.ymaps) {
//       init();
//     } else if (!window.ymapsLoading) {
//       window.ymapsLoading = true;
//       const script = document.createElement('script');
//       // script.src = `https://api-maps.yandex.ru/2.1/?apikey=0de6cb5a-0eb9-407c-8c8f-886a0a3fe2d9&lang=ru_RU`;
//       script.async = true;
//       script.onload = () => {
//         window.ymapsLoading = false;
//         window.ymaps.ready(init);
//       };
//       document.head.appendChild(script);
//     } else {
//       const interval = setInterval(() => {
//         if (window.ymaps) {
//           clearInterval(interval);
//           init();
//         }
//       }, 100);
//     }
//   }, []);

//   useEffect(() => {
//     if (ymapsRef.current) {
//       updatePlacemark();
//     }
//   }, [address]);

//   const init = () => {
//     ymapsRef.current = window.ymaps;
//     ymapsRef.current.geocode(address).then((res) => {
//       const coordinates = res.geoObjects.get(0).geometry.getCoordinates();
//       setFormData({ ...formData, latitude: coordinates[0], longitude: coordinates[1] }); 
//       const map = new ymapsRef.current.Map(mapRef.current, {
//         center: coordinates,
//         zoom: 15,
//       });
//       placemarkRef.current = new ymapsRef.current.Placemark(coordinates);
//       map.geoObjects.add(placemarkRef.current);
//     });
//   };

//   const updatePlacemark = () => {
//     ymapsRef.current.geocode(address).then((res) => {
//       const coordinates = res.geoObjects.get(0).geometry.getCoordinates();
//       setFormData({ ...formData, latitude: coordinates[0], longitude: coordinates[1] }); 
//       placemarkRef.current.geometry.setCoordinates(coordinates);
//       mapRef.current.setCenter(coordinates);
//     });
//   };

//   return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
// };

// export default YandexMap;

import React, { useEffect, useState, useRef } from "react";
import { YMaps, Map, Placemark, withYMaps } from "@pbe/react-yandex-maps";

const YandexMapComponent = ({ ymaps, address, formData, setFormData }) => {
  const [coordinates, setCoordinates] = useState([formData.latitude, formData.longitude]);
  const [loaded, setLoaded] = useState(false);
  const previousAddressRef = useRef(address);

  useEffect(() => {
    if (ymaps && address && address !== previousAddressRef.current) {
      previousAddressRef.current = address;
      ymaps.geocode(address).then((res) => {
        const geoObject = res.geoObjects.get(0);
        if (geoObject) {
          const newCoordinates = geoObject.geometry.getCoordinates();
          setCoordinates(newCoordinates);
          setFormData({ ...formData, latitude: newCoordinates[0], longitude: newCoordinates[1] });
          setLoaded(true);
        }
      });
    }
  }, [ymaps, address, setFormData, formData]);

  return (
    loaded && (
      <Map state={{ center: coordinates, zoom: 15 }} style={{ width: "100%", height: "400px" }}>
        <Placemark geometry={coordinates} />
      </Map>
    )
  );
};

const ConnectedMap = withYMaps(YandexMapComponent, true, [["geocode"]]);

const YandexMap = ({ address, formData, setFormData }) => {
  return (
    <YMaps query={{ apikey: "0de6cb5a-0eb9-407c-8c8f-886a0a3fe2d9" }}>
      <ConnectedMap address={address} formData={formData} setFormData={setFormData} />
    </YMaps>
  );
};

export default YandexMap;
