import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const CustomTextField = styled(TextField)(({ theme }) => ({
//   '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
//     borderColor: theme.palette.secondary.main,
//   },
  '& .MuiInputBase-root': {
    fontSize: '0.875rem',
  },
}));

const ThemedTextField = (props) => {
  return (
    <CustomTextField variant="outlined" size="small" {...props} />
  );
};

export default ThemedTextField;
