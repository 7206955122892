import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isClickPostAnAd: false,
};

const isClickPostAdSlice = createSlice({
  name: 'postAd',
  initialState,
  reducers: {
    setClickPostAnAd(state, action) {
      state.isClickPostAnAd = action.payload;
    },
  },
});

export const { setClickPostAnAd } = isClickPostAdSlice.actions;

export default isClickPostAdSlice.reducer;
