// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   TextField,
//   Button,
//   List,
//   ListItem,
//   ListItemText,
// } from "@mui/material";
// import { io } from "socket.io-client";
// import { getMessage, postMessage } from "../../../api/chat";

// const Chat = ({ ad, currentUserId, recipientId }) => {
//   console.log(ad)
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState("");
//   const [ws, setWs] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       const props = { adId: ad.ID, senderID: currentUserId, receiverID: recipientId };

//       const data = await getMessage(props);
//       setMessages(data.data);
//     };

//     fetchData();

//     const socket = new WebSocket("ws://localhost:8080/ws");

//     socket.onopen = () => {
//       console.log("WebSocket connection opened");
//     };

//     socket.onmessage = (event) => {
//       const message = JSON.parse(event.data);
//       setMessages((prevMessages) => [...prevMessages, message]);
//     };

//     socket.onclose = () => {
//       console.log("WebSocket connection closed");
//     };

//     setWs(socket);

//     return () => {
//       socket.close();
//     };
//   }, [ad, currentUserId, recipientId]);

//   const handleSendMessage = () => {
//     const message = {
//       sender_id: currentUserId,
//       receiver_id: recipientId,
//       ad_id: ad.ID,
//       content: newMessage,
//       created_at: new Date().toISOString(),
//     };

//     if (ws && ws.readyState === WebSocket.OPEN) {
//         ws.send(JSON.stringify(message));
//         setNewMessage('');
//     }
//     postMessage(message);
//   };

//   return (
//     <Box>
//       <List>
//         {messages?.map((message) => (
//           <ListItem key={message.id}>
//             <ListItemText
//               primary={message.content}
//               secondary={
//                 message.sender_id === currentUserId
//                   ? "Вы"
//                   : "Другой пользователь"
//               }
//             />
//           </ListItem>
//         ))}
//       </List>
//       <Box display="flex" mt={2}>
//         <TextField
//           fullWidth
//           value={newMessage}
//           onChange={(e) => setNewMessage(e.target.value)}
//           placeholder="Напишите сообщение..."
//         />
//         <Button variant="contained" color="primary" onClick={handleSendMessage}>
//           Отправить
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default Chat;
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Divider,
  Container
} from "@mui/material";
// import useWebSocket from "../../../hooks/useWebSocket";
import { fetchChats, fetchMessages } from "../../../api/chat";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../config/slices/userSlice";
import { getAdById } from "../../../api/ads";
import { getCategoryByID } from "../../../api/categories";
import { getUserById } from "../../../api/auth";

const ChatPage = () => {
  const { chatId } = useParams();
  const { adId } = useParams();
  //   const userInfo = useSelector(selectUserInfo);
  //   const [messages, setMessages] = useState([]);
  //   const [content, setContent] = useState("");
  //   const socketRef = useRef(null);

  //   useEffect(() => {
  //       const fetchData = async () => {
  //         const response = await fetchMessages(chatId)
  //         setMessages(response)
  //       }

  //       fetchData()
  //   }, [chatId]);

  //   useEffect(() => {
  //     const connectWebSocket = () => {
  //       const socket = new WebSocket(`ws://localhost:8080/ws`);
  //       socketRef.current = socket;

  //       socket.onopen = () => {
  //         console.log("WebSocket connection established");
  //       };

  //       socket.onmessage = (event) => {
  //         const message = JSON.parse(event.data);
  //         setMessages((prevMessages) => [...prevMessages, message]);
  //       };

  //       socket.onerror = (error) => {
  //         console.error("WebSocket error:", error);
  //       };

  //       socket.onclose = (event) => {
  //         console.log(`WebSocket connection closed: ${event.reason} (code: ${event.code})`);
  //         // Если соединение закрыто не по нашей инициативе, пробуем подключиться снова
  //         if (event.code !== 1000) {
  //           console.log("Reconnecting...");
  //           setTimeout(() => {
  //             if (socketRef.current.readyState === WebSocket.CLOSED) {
  //               connectWebSocket();
  //             }
  //           }, 5000); // Увеличенный интервал перед повторным подключением
  //         }
  //       };
  //     };

  //     connectWebSocket();

  //     return () => {
  //       if (socketRef.current) {
  //         socketRef.current.close(1000, 'Component unmounting');
  //       }
  //     };
  //   }, []);

  //   const handleSend = () => {
  //     if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
  //       console.log(chatId)
  //       console.log(content)
  //       socketRef.current.send(JSON.stringify({ chat_id: Number(chatId), content, sender_id: userInfo.id }));
  //       setContent('');
  //     } else {
  //       console.error("WebSocket is not open");
  //     }
  //   };

  //   return (
  //     <div>
  //       <h1>Chat</h1>
  //       <div>
  //         {messages.map((message, index) => (
  //           <div key={index}>{message.content}</div>
  //         ))}
  //       </div>
  //       <TextField value={content} onChange={(e) => setContent(e.target.value)} />
  //       <Button variant="contained" color="primary" onClick={handleSend}>
  //         Отправить
  //       </Button>
  //     </div>
  //   );
  // };

  // export default ChatPage;

  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = useSelector(selectUserInfo);
  const [messages, setMessages] = useState([]);
  const [content, setContent] = useState("");
  const socketRef = useRef(null);
  const [adInfo, setAdInfo] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [users, setUsers] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const messagesResponse = await fetchMessages(chatId);
      setMessages(messagesResponse);

      // Fetch users from messages
      const userPromises = messagesResponse?.map((message) =>
        getUserById(message.sender_id)
      );
      const userResponses = await Promise.all(userPromises);
      const usersMap = userResponses.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
      setUsers(usersMap);

      const dataAd = await getAdById(adId);
      setAdInfo(dataAd);
      const dataCategory = await getCategoryByID(dataAd.category_id);
      setCategoryName(dataCategory.name_ru);
    };

    fetchData();
  }, [chatId, adId]);

  useEffect(() => {
    const connectWebSocket = () => {
      const socket = new WebSocket(`ws://${API_URL}/ws`);
      socketRef.current = socket;

      socket.onopen = () => {
        console.log("WebSocket connection established");
      };

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        setMessages((prevMessages) => [...prevMessages, message]);
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = (event) => {
        console.log(
          `WebSocket connection closed: ${event.reason} (code: ${event.code})`
        );
        if (event.code !== 1000) {
          console.log("Reconnecting...");
          setTimeout(() => {
            if (socketRef.current.readyState === WebSocket.CLOSED) {
              connectWebSocket();
            }
          }, 5000);
        }
      };
    };

    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close(1000, "Component unmounting");
      }
    };
  }, []);

  const handleSend = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(
        JSON.stringify({
          chat_id: Number(chatId),
          content,
          sender_id: userInfo.id,
        })
      );
      setContent("");
    } else {
      console.error("WebSocket is not open");
    }
  };

  const getUserDisplayName = (user) => {
    if (user.first_name || user.last_name) {
      return `${user.first_name || ""} ${user.last_name || ""}`.trim();
    }
    return user.phone_number || "Unknown";
  };

  return (
    <Container
      sx={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        m: "20px auto",
      }}
    >
      <Box
        sx={{
          padding: 2,
          border: "1px solid #ddd",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          height: "80vh",
          width: "100%"
        }}
      >
        <Typography variant="h6">
          {categoryName} {adInfo.price} тг
        </Typography>
        <Box sx={{ flexGrow: 1, overflowY: "auto", marginBottom: 2 }}>
          <List>
            {messages?.map((message) => {
              const user = users[message.sender_id] || {};
              const isSender = message.sender_id === userInfo.id;
              return (
                <React.Fragment key={message.ID}>
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: isSender ? "row-reverse" : "row",
                      marginBottom: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        p: "10px",
                        borderRadius: "10px",
                        backgroundColor: isSender ? "#e1ffc7" : "#f1f1f1",
                      }}
                    >
                      <Avatar>
                        {user.image_url ? (
                          <img
                            src={`${API_URL}/${user.image_url}`}
                            alt={`${user.first_name} ${user.last_name}`}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          `${user.first_name?.charAt(0) || ""}${
                            user.last_name?.charAt(0) || ""
                          }`
                        )}
                      </Avatar>
                      <Box
                        sx={{
                          maxWidth: "70%",
                          padding: 1,
                          borderRadius: "8px",
                          textAlign: isSender ? "right" : "left",
                        }}
                      >
                        <Typography variant="body2">
                          {getUserDisplayName(user)}
                        </Typography>
                        <Typography variant="body1">
                          {message.content}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                  {/* <Divider /> */}
                </React.Fragment>
              );
            })}
          </List>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Введите сообщение..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ marginRight: 1 }}
          />
          <Button variant="contained" color="primary" onClick={handleSend}>
            Отправить
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ChatPage;
