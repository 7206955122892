import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import { useTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { setClickPostAnAd } from '../../config/slices/isClickPostAdSlice';
// import { Link } from '@mui/material';
import { setUserInfo } from '../../config/slices/userSlice';
import { Grid } from '@mui/material';

const Header = ({ toggleTheme, isDarkMode }) => {
  const { t, i18n } = useTranslation();
  const auth = localStorage.getItem("access_token")
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'ru');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isActive = (path) => location.pathname.includes(path);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePostAnAd = () => {
    setAnchorEl(null);
    dispatch(setClickPostAnAd(true));
    auth ? navigate("/ad") : navigate("/auth")
  };

  const handleRrofile = () => {
    setAnchorEl(null);
    auth ? navigate("/profile") : navigate("/auth")
  }

  const handleRrofileChats = () => {
    setAnchorEl(null);
    auth ? navigate("/profile/chats") : navigate("/auth")
  }

  const handleLogin = () => {
    setAnchorEl(null);
    navigate("/auth")
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('language', newLanguage);
  };

  const handleExit = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setUserInfo({
      id: null,
      phone_number: null,
      first_name: null,
      last_name: null,
      email: null,
      region_id: null
    })
    navigate("/")
    setAnchorEl(null);
  }

  return (
    <Box >
      <AppBar position="static" sx={{ backgroundColor: theme.palette.background.default, boxShadow: "none", borderBottom: `1px solid ${theme.palette.border.main}`, backgroundImage: "none" }}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Link to="/" style={{ textDecoration: 'none', color: theme.palette.text.primary, flexGrow: 1, cursor: 'pointer' }}>
                <Typography variant="h3" sx={{ fontWeight: "bold", fontFamily: "Merriweather" }}>
                  Maltap.kz
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={5} container justifyContent="flex-end">
              <Button onClick={handleRrofileChats} style={{
                padding: "5px 20px",
                textDecoration: 'none',
                color: theme.palette.text.primary,
                margin: "0 10px",
                border: isActive('/chats') ? `2px solid ${theme.palette.background.white}` : 'none',
                borderRadius: "50px",
                backgroundColor: isActive('/chats') ? theme.palette.background.white : 'transparent'
              }}>
                <Typography variant="button" sx={{ fontFamily: "Inter", textTransform: "uppercase" }}>
                  Сообщения
                </Typography>
              </Button>
              <Button onClick={handlePostAnAd} style={{
                padding: "5px 20px",
                textDecoration: 'none',
                color: theme.palette.text.primary,
                margin: "0 10px",
                border: isActive('/ad') ? `2px solid ${theme.palette.background.white}` : 'none',
                borderRadius: "50px",
                backgroundColor: isActive('/ad') ? theme.palette.background.white : 'transparent'
              }}>
                <Typography variant="button" sx={{ fontFamily: "Inter", textTransform: "uppercase" }}>
                  ПОДАТЬ ОБЪЯВЛЕНИЕ
                </Typography>
              </Button>
              {!auth &&
                <Button onClick={handleLogin} style={{
                  padding: "5px 20px",
                  textDecoration: 'none',
                  color: theme.palette.text.primary,
                  margin: "0 10px",
                  border: isActive('/auth') ? `2px solid ${theme.palette.background.white}` : 'none',
                  borderRadius: "50px",
                  backgroundColor: isActive('/auth') ? theme.palette.background.white : 'transparent'
                }}>
                  <Typography variant="button" sx={{ fontFamily: "Inter", textTransform: "uppercase" }}>
                    ВОЙТИ
                  </Typography>
                </Button>
              }
            </Grid>
            <Grid item xs={3} container justifyContent="flex-end">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleRrofile}
                sx={{ color: theme.palette.text.primary }}
              >
                <PermIdentityIcon />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                // onClick={handleMenu}
                sx={{ color: theme.palette.text.primary }}
              >
                <FavoriteBorderIcon />
              </IconButton>
            </Grid>
          </Grid>
          {/* <FormControl variant="standard" sx={{ marginRight: 2, minWidth: 60 }} size="small">
            <Select
              value={language}
              onChange={handleLanguageChange}
              label="Language"
              inputProps={{
                name: 'language',
                id: 'language-select',
              }}
            >
              <MenuItem value="ru">RU</MenuItem>
              <MenuItem value="kz">KK</MenuItem>
            </Select>
          </FormControl>
          <Button variant="text" sx={{ color: theme.palette.text.primary }} onClick={toggleTheme}>
            {isDarkMode ? <WbSunnyOutlinedIcon /> : <DarkModeOutlinedIcon />}
          </Button>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            sx={{ color: theme.palette.text.primary }}
          >
            <AccountCircleOutlinedIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleRrofile}>Личный кабинет</MenuItem>
            {!auth ? (
              <MenuItem onClick={handleRrofile}>Регистрация</MenuItem>
            ) : (
              [
                <MenuItem key="ads" onClick={handleRrofile}>Объявления</MenuItem>,
                <MenuItem key="messages" onClick={handleRrofile}>Сообщения</MenuItem>,
                <MenuItem key="settings" onClick={handleRrofile}>Настройки</MenuItem>,
                <MenuItem key="exit" onClick={handleExit}>Выйти</MenuItem>,
              ]
            )}
          </Menu>
          <Button
            variant="outlined"
            sx={{ color: theme.palette.text.primary, borderColor: theme.palette.text.primary, ml: "15px" }}
            onClick={handlePostAnAd}
          >
            {t("header.PostAnAd")}
          </Button> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
