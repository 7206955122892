import React, { useState, useRef } from 'react';
import { Grid, IconButton, Typography, Breadcrumbs, Box, Paper } from '@mui/material';
import { AddPhotoAlternate, Delete } from '@mui/icons-material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '@mui/material/styles';

const ItemType = 'IMAGE';

const DraggableImage = ({ image, index, moveImage, handleRemoveImage }) => {
  const ref = useRef(null);
  
  const [, drop] = useDrop({
    accept: ItemType,
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items width
      // When dragging rightwards, only move when the cursor is beyond 50%
      // When dragging leftwards, only move when the cursor is below 50%
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      moveImage(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Grid item ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Paper
        elevation={3}
        sx={{
          width: 150,
          height: 150,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          cursor: 'pointer'
        }}
      >
        <img
          src={URL.createObjectURL(image.file)}
          alt={`uploaded ${index}`}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        {index === 0 && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              bgcolor: 'secondary.main',
              color: 'white',
              px: 1,
              py: 0.5,
              borderRadius: '0 5px 0 0',
            }}
          >
            Главное
          </Box>
        )}
        <IconButton
          style={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
          }}
          size="small"
          onClick={() => handleRemoveImage(index)}
        >
          <Delete />
        </IconButton>
      </Paper>
    </Grid>
  );
};

const ImagesStep = ({ formData, setFormData }) => {
  const theme = useTheme();
  const [error, setError] = useState('');

  const handleFileChange = (event, index) => {
    const files = Array.from(event.target.files);
    if (files.length + formData.images.length > 4) {
      setError('Можно загрузить не более 4 изображений.');
      return;
    }
    const newImages = [...formData.images];
    newImages[index] = { id: uuidv4(), file: files[0] };
    setFormData({ ...formData, images: newImages });
    setError('');
  };

  const handleRemoveImage = (index) => {
    const newImages = [...formData.images];
    newImages.splice(index, 1);
    setFormData({ ...formData, images: newImages });
  };

  const moveImage = (fromIndex, toIndex) => {
    const newImages = Array.from(formData.images);
    const [movedImage] = newImages.splice(fromIndex, 1);
    newImages.splice(toIndex, 0, movedImage);
    setFormData({ ...formData, images: newImages });
  };

  return (
    <Box>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography variant="h6">{formData.categoryName}</Typography>
        <Typography variant="h6">{formData.subcategoryName}</Typography>
        {formData.subSubcategoryName && <Typography variant="h6">{formData.subSubcategoryName}</Typography>}
      </Breadcrumbs>
      {error && <Typography color="error">{error}</Typography>}
      <DndProvider backend={HTML5Backend}>
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          {formData.images?.map((item, index) => (
            <DraggableImage
              key={item.id}
              image={item}
              index={index}
              moveImage={moveImage}
              handleRemoveImage={handleRemoveImage}
            />
          ))}
          {[...Array(4 - formData.images.length)]?.map((_, index) => (
            <Grid item key={`empty-${index}`}>
              <Paper
                elevation={3}
                sx={{
                  width: 150,
                  height: 150,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  backgroundColor: theme.palette.secondary.light,
                  color: theme.palette.background.default,
                  cursor: 'pointer'
                }}
              >
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={`image-upload-empty-${index}`}
                  type="file"
                  onChange={(event) => handleFileChange(event, formData.images.length + index)}
                />
                <label htmlFor={`image-upload-empty-${index}`}>
                  <Typography>Добавить фото</Typography>
                </label>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DndProvider>
    </Box>
  );
};

export default ImagesStep;
