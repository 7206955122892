import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import ContactDetails from './ContactDetails';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';

const Settings = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Tabs value={tabValue} onChange={handleChange}>
        <Tab label="Контактные данные" />
        <Tab label="Изменить пароль" />
        <Tab label="Удалить учетку" />
      </Tabs>
      <Box mt={2}>
        {tabValue === 0 && <ContactDetails />}
        {tabValue === 1 && <ChangePassword />}
        {tabValue === 2 && <DeleteAccount />}
      </Box>
    </Box>
  );
};

export default Settings;
