// store/filterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    categoryId: '',
    subcategoryId: '',
    subSubcategoryId: '',
    priceFrom: '',
    priceTo: '',
    keyword: '',
    regionId: ''
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilters: (state, action) => {
            return { ...state, ...action.payload };
        },
        clearFilters: () => {
            return initialState;
        }
    }
});

export const { setFilters, clearFilters } = filterSlice.actions;
export default filterSlice.reducer;
