import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: 2,
        textAlign: 'center',
        backgroundColor: theme.palette.secondary.main,
        borderTop: `1px solid ${theme.palette.border.main}`,
        color: "#fff"
      }}
    >
      <Typography variant="body1">
        &copy; 2024 MALTAP
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Link to="/terms-of-service" style={{ marginRight: '15px', color: theme.palette.text.primary }}>
          Условия использования
        </Link>
        <Link to="/privacy-policy" style={{ marginRight: '15px', color: theme.palette.text.primary }}>
          Политика конфиденциальности
        </Link>
        <Link to="/contact-us" style={{ color: theme.palette.text.primary }}>
          Контакты
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
