import React from 'react';
import { Box, TextField, Typography, Grid, Breadcrumbs, Link, InputAdornment } from '@mui/material';

const DetailsStep = ({ formData, setFormData }) => {
  return (
    <Box>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography variant="h6">{formData.categoryName}</Typography>
        <Typography variant="h6">{formData.subcategoryName}</Typography>
        {formData.subSubcategoryName && <Typography variant="h6">{formData.subSubcategoryName}</Typography>}
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            margin="normal"
            label="Цена"
            type="number"
            value={formData.price || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">тг</InputAdornment>,
            }}
            onChange={(e) => setFormData({ ...formData, price: Number(e.target.value) })}
          /></Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            label="Описание"
            multiline
            rows={4}
            value={formData.description || ''}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailsStep;
