import axiosInstance from './axios';

export const getRegions = async (parentId, language) => {
    try {
        const response = await axiosInstance.get('/regions', {
            params: {
                parent_id: parentId,
                lang: language,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching regions:', error);
        throw error;
    }
};
