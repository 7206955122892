import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Box, TextField, Button, Typography, Link, FormControlLabel, Checkbox, Stack, Alert } from '@mui/material';
import { checkPhoneNumber, sendCode, verifyCode, createPassword, login, reactivateAccount } from '../api/auth';
import CustomTextField from '../components/TextField/TextField';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setClickPostAnAd } from '../config/slices/isClickPostAdSlice';
import { setAccessToken, setUserInfo } from '../config/slices/userSlice';

const AuthPage = ({ isDarkMode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [step, setStep] = useState('enterPhone');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const isClickPostAnAd = useSelector((state) => state.postAd.isClickPostAnAd);
    const dispatch = useDispatch();

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };

    const handleCheckPhone = async () => {
        try {
            const response = await checkPhoneNumber(phone);
            if (response.data.status === 'ok') {
                if (response.data.type === 'password') {
                    setStep('enterPassword');
                } else if (response.data.type === 'code') {
                    // const phoneNumber = `+7${phone}`
                    // await sendCode(phoneNumber);
                    // setStep('enterCode');
                    setStep('createPassword');
                }
            } else if (response.data.status === 'inactive') {
                setStep('reactivate');
                setMessage(response.data.message);
            } else {
                setError('Что-то пошло не так, обратитесь в администрацию');
            }
        } catch (error) {
            setError('Error checking phone number');
        }
    };

    const handleReactivate = async () => {
        try {
            const phoneNumber = `+7${phone}`
            await sendCode(phoneNumber);
            setStep('enterReactivationCode');
        } catch (error) {
            setError('Error sending code');
        }
    };

    const handleVerifyReactivationCode = async () => {
        try {
            const response = await reactivateAccount(phone, code);
            if (response.data.message === 'User reactivated successfully') {
                setStep('enterPassword');
                setMessage('Account reactivated. Please create a new password.');
            } else {
                setError('Invalid code');
            }
        } catch (error) {
            setError('Error verifying code');
        }
    };

    const handleForgotPassword = async () => {
        try {
            const phoneNumber = `+7${phone}`
            await sendCode(phoneNumber);
            setStep('enterCode');
        } catch (error) {
            setError('Error sending code');
        }
    };

    const handleVerifyCode = async () => {
        try {
            const response = await verifyCode(phone, code);
            if (response.data.message === 'User verified') {
                setStep('createPassword');
            } else {
                setError('Invalid code');
            }
        } catch (error) {
            setError('Error verifying code');
        }
    };

    const handleCreatePassword = async () => {
        try {
            const response = await createPassword(phone, password);
            if (response.data.status === 'ok') {
                const { access_token, refresh_token, user_info } = response.data;

                dispatch(setUserInfo(user_info));
                dispatch(setAccessToken(access_token))
                localStorage.setItem("access_token", access_token)

                document.cookie = `refresh_token=${refresh_token}; path=/; Secure`;

                dispatch(setClickPostAnAd(false));
                isClickPostAnAd ? navigate("/ad") : navigate('/profile');
            } else {
                setError('Error creating password');
            }
        } catch (error) {
            setError('Error creating password');
        }
    };

    const handleLogin = async () => {
        try {
            const response = await login(phone, password);
            if (response.data.status === 'ok') {
                const { access_token, refresh_token, user_info } = response.data;
                dispatch(setUserInfo(user_info));
                dispatch(setAccessToken(access_token))
                localStorage.setItem("access_token", access_token)

                document.cookie = `refresh_token=${refresh_token}; path=/; Secure`;

                dispatch(setClickPostAnAd(false));
                isClickPostAnAd ? navigate("/ad") : navigate('/profile');
            } else {
                setError('Invalid credentials');
            }
        } catch (error) {
            setError(t("auth.errorLogin"));
        }
    };

    return (
        <Container
            sx={{
                justifyContent: 'center',
                display: "flex",
                alignItems: "center",
                p: "100px 0"
            }}
        >
            <Stack spacing={2} sx={{ width: '100%', maxWidth: 400, padding: "40px 50px", boxShadow: isDarkMode ? "0 5px 10px 0 rgba(237, 231, 225, 0.01)" : "0 5px 10px 0 rgba(28,24,25,.15)" }}>
                <Box>
                    <Typography sx={{ mb: "20px" }}>{t("auth.title")}</Typography>
                    {step === 'enterPhone' && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <CustomTextField
                                label="Номер телефона"
                                value={phone}
                                onChange={handlePhoneChange}
                                fullWidth
                                InputProps={{ startAdornment: '+7 ' }}
                                maxLength={10}
                            />
                            <Button variant="contained" onClick={handleCheckPhone} fullWidth >Продолжить</Button>
                        </Box>
                    )}
                    {step === 'enterPassword' && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <CustomTextField
                                label="Номер телефона"
                                value={phone}
                                fullWidth
                                InputProps={{ readOnly: true, startAdornment: '+7 ' }}
                            />
                            <CustomTextField
                                label="Пароль"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                fullWidth
                            />
                            {error && <Alert severity="error">{error}</Alert>}
                            <Button variant="contained" onClick={handleLogin} fullWidth>Войти</Button>
                            <Link component="button" onClick={handleForgotPassword} sx={{ alignSelf: 'center', mt: 1 }}>Забыли пароль?</Link>
                        </Box>
                    )}
                    {step === 'enterCode' && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <CustomTextField
                                label="Код подтверждения"
                                value={code}
                                onChange={handleCodeChange}
                                fullWidth
                            />
                            {error && <Alert severity="error">{error}</Alert>}
                            <Button variant="contained" onClick={handleVerifyCode} fullWidth>Подтвердить</Button>
                        </Box>
                    )}
                    {step === 'createPassword' && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <CustomTextField
                                label="Новый пароль"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                fullWidth
                            />
                            {error && <Alert severity="error">{error}</Alert>}
                            <Button variant="contained" onClick={handleCreatePassword} fullWidth>Создать пароль</Button>
                        </Box>
                    )}
                    {step === 'reactivate' && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography>{message}</Typography>
                            <Button variant="contained" onClick={handleReactivate} fullWidth>Восстановить аккаунт</Button>
                        </Box>
                    )}
                    {step === 'enterReactivationCode' && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <CustomTextField
                                label="Код подтверждения"
                                value={code}
                                onChange={handleCodeChange}
                                fullWidth
                            />
                            {error && <Alert severity="error">{error}</Alert>}
                            <Button variant="contained" onClick={handleVerifyReactivationCode} fullWidth>Подтвердить</Button>
                        </Box>
                    )}
                </Box>
            </Stack>
        </Container>
    );
};

export default AuthPage;
