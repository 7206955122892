import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Alert } from '@mui/material';
import { getAds } from '../../../api/ads';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../config/slices/userSlice';
import { formatNumber } from '../../../utils';
import { getCategoryByID } from '../../../api/categories';

const Settings = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = useSelector(selectUserInfo);
  const [tabValue, setTabValue] = useState(0);
  const [ads, setAds] = useState([]);
  const [categories, setCategories] = useState({});
  const [error, setError] = useState('');

  const tabStatusMap = {
    0: 'active',
    1: 'pending',
    2: 'unpaid',
    3: 'inactive',
    4: 'rejected'
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchAds = async () => {
      const params = {
        user_id: userInfo.id,
        status: tabStatusMap[tabValue]
      };

      try {
        const data = await getAds(params);
        setAds(data);
      } catch (error) {
        setError(error.message);
      }
    };

    if (userInfo.id) {
      fetchAds();
    }
  }, [userInfo, tabValue]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesMap = {};
      for (const ad of ads) {
        try {
          const category = await getCategoryByID(ad.category_id);
          categoriesMap[ad.ID] = category.name_ru;
        } catch (error) {
          console.error(`Error fetching category for ad ID ${ad.ID}:`, error);
        }
      }
      setCategories(categoriesMap);
    };

    if (ads.length > 0) {
      fetchCategories();
    }
  }, [ads]);

  const formatImageUrl = (url) => {
    if (!url) return '';
    return `${API_URL}/${url.replace(/\\/g, '/')}`;
  };

  return (
    <Box>
      <Tabs value={tabValue} onChange={handleChange}>
        <Tab label="Активные" />
        <Tab label="Ожидающие" />
        <Tab label="Неоплаченные" />
        <Tab label="Неактивные" />
        <Tab label="Отклоненные" />
      </Tabs>
      <Box mt={2}>
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {ads?.map((ad) => (
              <Grid item key={ad.ID}>
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      alt={ad.name_ru}
                      height="140"
                      image={formatImageUrl(ad.images[0].url)}
                      title={ad.name_ru}
                    />
                    <CardContent>
                      <Typography variant="h6" component="div" align="center">
                        {categories[ad.ID] || 'Загрузка...'}
                      </Typography>
                      <Typography variant="body1" component="div" align="center">
                        {formatNumber(ad.price)} ₸
                      </Typography>
                      <Typography variant="body2" component="div" align="center">
                        {ad.region.NameRus}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          {error && (
            <Alert severity="error">{error}</Alert>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
