import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Box, Typography, Button, Card, CardMedia, Avatar, Grid, Snackbar, Alert } from '@mui/material';
import { getAdById } from '../api/ads';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getCategoryByID } from '../api/categories';
import { getUserById } from '../api/auth';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../config/slices/userSlice';
import { createChat } from '../api/chat';
import { formatDate, formatNumber } from '../utils';
import PersonIcon from '@mui/icons-material/Person';

const DetailsAd = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { id } = useParams();
    const navigate = useNavigate();
    const currentUserInfo = useSelector(selectUserInfo);

    const [item, setItem] = useState(null);
    const [categoryName, setCategoryName] = useState("");
    const [userInfo, setUserInfo] = useState(null);
    const [phoneVisible, setPhoneVisible] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getAdById(id);
            setItem(data);
            const dataCategory = await getCategoryByID(data.category_id);
            setCategoryName(dataCategory.name_ru);
            const dataUser = await getUserById(data.user_id);
            setUserInfo(dataUser);
        };

        fetchData();
    }, [id]);

    if (!item) return <div>Loading...</div>;

    const formatImageUrl = (url) => {
        if (!url) return '';
        return `${API_URL}/${url.replace(/\\/g, '/')}`;
    };

    const getInitials = (firstName, lastName) => {
        return `${lastName?.charAt(0)}${firstName?.charAt(0)}`;
    };

    const getRandomColor = () => {
        const colors = ['#FFB6C1', '#ADD8E6', '#90EE90', '#FFA07A', '#20B2AA', '#9370DB'];
        return colors[Math.floor(Math.random() * colors.length)];
    };

    const handlePhoneClick = () => {
        const token = localStorage.getItem('access_token');
        if (token) {
            setPhoneVisible(true);
        } else {
            setOpenSnackbar(true);
        }
    };

    const handleChatClick = async () => {
        try {
            const response = await createChat(Number(id));
            const chatId = response.data.ID;
            const adId = response.data.ad_id;
            navigate(`/chats/${chatId}/${adId}`);
        } catch (error) {
            console.error('Failed to create chat', error);
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container
            sx={{
                justifyContent: 'center',
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                m: "20px auto",
            }}
        >
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {currentUserInfo.id === userInfo?.id ? 'Вы не можете написать самому себе' : 'Авторизуйтесь, чтобы воспользоваться этой функцией'}
                </Alert>
            </Snackbar>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Carousel showArrows={true} infiniteLoop={true} showThumbs={true} swipeable emulateTouch>
                        {item.images?.map((image, index) => (
                            <div key={index}>
                                <img src={formatImageUrl(image.url)} alt={`${categoryName + " " + index}`} />
                            </div>
                        ))}
                    </Carousel>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box>
                        <Typography variant="h4">{categoryName}</Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                            Опубликовано: {formatDate(item.CreatedAt)}
                        </Typography>
                        <Typography variant="h5" color="primary" sx={{ marginY: 2 }}>
                            {formatNumber(item.price)} ₸
                        </Typography>
                        <Button variant="contained" color="primary" sx={{ marginRight: 2 }} onClick={handleChatClick}>
                            Сообщение
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handlePhoneClick}
                            component={phoneVisible ? 'a' : 'button'}
                            href={phoneVisible ? `tel:${userInfo?.phone_number}` : undefined}
                        >
                            {phoneVisible ? userInfo?.phone_number : 'Посмотреть телефон'}
                        </Button>
                    </Box>
                    <Box sx={{ m: "30px 0" }}>
                        <Typography variant="body1">Информация о продавце</Typography>
                        <Card sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                            {userInfo?.avatar_url ? (
                                <CardMedia
                                    component="img"
                                    sx={{ width: 64, height: 64, borderRadius: '50%' }}
                                    image={formatImageUrl(userInfo.avatar_url)}
                                    alt={`${userInfo.last_name} ${userInfo.first_name}`}
                                />
                            ) : (
                                <Avatar
                                    sx={{ width: 64, height: 64, fontSize: '24px' }}
                                >
                                    <PersonIcon fontSize='large' />
                                    {/* {getInitials(userInfo?.first_name, userInfo?.last_name)} */}
                                </Avatar>
                            )}
                            <Box sx={{ marginLeft: 2 }}>
                                <Typography variant="subtitle1">{userInfo?.last_name} {userInfo?.first_name}</Typography>
                                <Button variant="text" color="primary">Все объявления автора</Button>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} sm={12} sx={{ boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)" }}>
                        <Typography variant="h6">Описание</Typography>
                        <Typography paragraph>
                            {categoryName}
                            <br />
                            {item.description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default DetailsAd;
