import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./config/store";
import Header from "./modules/header/Header";
import Footer from "./modules/footer/Footer";
import AuthPage from "./pages/AuthPage";
import "./i18n";
import AdPage from "./pages/AdPage";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { darkTheme, lightTheme } from "./theme/theme";
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import DetailsAd from "./pages/DetailsAd";
import ChatPage from "./modules/profile/chats/ChatPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import ContactUs from "./pages/ContactUs";
import "./assets/styles/main.scss";
import Chats from "./modules/profile/chats/Chats";

const App = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("isDarkMode");
    return savedTheme ? JSON.parse(savedTheme) : false;
  });

  useEffect(() => {
    localStorage.setItem("isDarkMode", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <div className="app-container">
              <Header toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
              <div className="content">
                <Routes>
                  <Route
                    path="/auth"
                    element={<AuthPage isDarkMode={isDarkMode} />}
                  />
                  <Route path="/ad" element={<AdPage />} />
                  <Route path="/" element={<HomePage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/detailsAd/:id" element={<DetailsAd />} />
                  <Route path="/chats/:chatId/:adId" element={<ChatPage />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-of-service"
                    element={<TermsOfService />}
                  />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/profile/chats" element={<ProfilePage />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
