import axiosInstance from './axios';

export const getCategories = async () => {
    const response = await axiosInstance.get('/categories');
    return response.data;
};

export const getSubcategories = async (categoryId) => {
    const response = await axiosInstance.get(`/categories/${categoryId}/subcategories`);
    return response.data;
};

export const createCategory = async (categoryData) => {
    return await axiosInstance.post('/categories', categoryData);
};

export const uploadCategoryImage = async (categoryId, file) => {
    const formData = new FormData();
    formData.append('file', file);

    return await axiosInstance.post(`/categories/${categoryId}/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export const getCategoryByID = async (categoryId) => {
    const response = await axiosInstance.get(`/categories/${categoryId}`);
    return response.data;
};
