import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInfo: {
        id: null,
        phone_number: null,
        first_name: null,
        last_name: null,
        email: null,
        region_id: null
    },
    accessToken: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload; // Обновляем userInfo напрямую
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload; // Обновляем userInfo напрямую
        },
        clearUser: (state) => {
            state.userInfo = initialState.userInfo; // Очищаем userInfo
            state.accessToken = null;
        },
    },
});

export const { setUserInfo, setAccessToken, clearUser } = userSlice.actions;
export const selectUserInfo = (state) => state.user.userInfo;
export const selectAccessToken = (state) => state.user.accessToken;

export default userSlice.reducer;
