import axiosInstance from "./axios";

// export const sendMessage = async (message) => {
//   return await axiosInstance.post("/checkPhone", message);
// };

// export const getMessage = async (props) => {
//   const { adId, senderID, receiverID } = props;
//   let url = `/messages?`;
//   if (adId) {
//     url += `&ad_id=${adId}`;
//   }
//   if (senderID) {
//     url += `&sender_id=${senderID}`;
//   }
//   if (receiverID) {
//     url += `&receiver_id=${receiverID}`;
//   }
//   return await axiosInstance.get(url);
// };

// export const postMessage = async (message) => {
//   return await axiosInstance.post(`/messages`, message);
// };

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// export const createChat = async (id) => {
//   console.log(id);
//   return await axiosInstance.post("/chats", { ad_id: id });
// };

// export const getAuthorChats = async (props) => {
//   const { authorID } = props;
//   let url = `/messages?author_id=${authorID}`;
//   return await axiosInstance.get(url);
// };

///////////////////////////////////////////////////////////////////////////////////////////////////////////

// src/redux/actions/chatActions.js

export const fetchChats = async () => {
  try {
    const response = await axiosInstance.get('/chats');
    return response.data
  } catch (error) {
    console.error('Failed to fetch chats', error);
  }
};

export const fetchMessages = async (chatId) => {
  try {
    const response = await axiosInstance.get(`/chats/${chatId}/messages`);
    return response.data
  } catch (error) {
    console.error('Failed to fetch messages', error);
  }
};

export const sendMessage = async (chatId, content) => {
  try {
    const response = await axiosInstance.post(`/chats/${chatId}/messages`, { content });
    return response.data
  } catch (error) {
    console.error('Failed to send message', error);
  }
};

export const createChat = async (id) => {
  return await axiosInstance.post("/chats", { ad_id: id });
};

