import axiosInstance from './axios';

export const createAd = async (adData) => {
  const response = await axiosInstance.post('/ads', adData);
  return response.data;
};

export const uploadAdImage = async (adId, file) => {
    const formData = new FormData();
    formData.append('file', file);

    return await axiosInstance.post(`/ads/${adId}/upload`, formData);
};

export const getAds = async (params) => {
  const {category_id, price_from, price_to, keyword, region_id, user_id, status} = params;
  let url = `/ads/filter?`;
  
  if (category_id) {
    url += `&category_id=${category_id}`;
  }
  if (price_from) {
    url += `&price_from=${price_from}`;
  }
  if (price_to) {
    url += `&price_to=${price_to}`;
  }
  if (keyword) {
    url += `&keyword=${keyword}`;
  }
  if (region_id) {
    url += `&region_id=${region_id}`;
  }
  if (user_id) {
    url += `&user_id=${user_id}`;
  }
  if (status) {
    url += `&status=${status}`;
  }

  const response = await axiosInstance.get(url);
  return response.data;
}

export const getAdById = async (id) => {
  const response = await axiosInstance.get(`/ads/${id}`);
  return response.data;
};