import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const ContactUs = () => {
    return (
        <Container maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Свяжитесь с нами
                </Typography>
                <Typography variant="body1" paragraph>
                    {/* Вставьте здесь ваши контактные данные */}
                    Вы можете связаться с нами по следующему адресу:
                </Typography>
                <Typography variant="body1" paragraph>
                    Адрес: г.Алматы, ул. Абая 130<br />
                    Телефон: +7 777 043 99 24<br />
                    Email: kz.maltap@gmail.com
                </Typography>
                <Typography variant="body1" paragraph>
                    Мы будем рады помочь вам с любыми вопросами или предложениями.
                </Typography>
            </Box>
        </Container>
    );
};

export default ContactUs;
