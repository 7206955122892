import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import RecommendationCard from './RecommendationCard';
import { getAds } from '../../api/ads';

const RecommendationsGrid = ({ recommendations }) => {
  // const [recommendations, setRecommendations] = useState([])

  // useEffect(() => {
  //   const getchData = async () => {
  //     const params = {}
  //     const data = await getAds(params)
  //     setRecommendations(data)
  //   }

  //   getchData()
  // }, [])

  return (
    <Stack
      spacing={{ xs: 2, sm: 3, mt: "20px" }}
      direction="row"
      useFlexGap
      sx={{ flexWrap: 'wrap' }}
    >
      {recommendations?.map(item => (
        // <Grid item xs={12} sm={2} md={2} key={item.ID}>

        <RecommendationCard item={item} />
        //  {/* </Grid> */ }
      ))}
    </Stack>
  )
};

export default RecommendationsGrid;