import { Box, Button, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import RecommendationsGrid from './RecommendationsGrid'; // Adjust the import path as necessary
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { getCategories } from '../../api/categories';
import { getAds } from '../../api/ads';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFilters } from '../../config/slices/filterSlice';
import queryString from 'query-string';

function Recommendations() {

  const theme = useTheme();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [ads, setAds] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories();
        setCategories(data);
      } catch (err) {
        setError('Ошибка при загрузке категорий');
      }
    };
    const fetchAds = async () => {
      try {
        const params = {}
        const data = await getAds(params);
        console.log(data)
        setAds(data);
      } catch (err) {
        setError('Ошибка при загрузке объявлений');
      }
    }
    fetchCategories();
    fetchAds();
  }, []);

  console.log(ads)

  const handleCategoryClick = async (category) => {
    try {
      setLoading(true);
      console.log(category)
      const params = {
        category_id: category?.ID || null
      }
      const data = await getAds(params);
      setAds(data);
      if(category) {
        setSelectedCategoryId(category.ID);
      } else {
        setSelectedCategoryId(null);
      }
      
    } catch (error) {
      console.error('Error fetching ads:', error);
      if (error.response) {
        console.error('Server response:', error.response.data);
        console.error('Server status:', error.response.status);
        console.error('Server headers:', error.response.headers);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Request setup error:', error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2, mb: 1, alignItems: "center" }} >
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" gap={2} justifyContent="flex-start">
          <Typography variant="h6" >Горячие предложения</Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ background: theme.palette.background.white, padding: "5px 25px", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)", borderRadius: "10px" }}
          >
            <Button onClick={() => handleCategoryClick()} sx={{color: theme.palette.text.black, borderBottom: selectedCategoryId === null ? `1px solid ${theme.palette.accent.main}`: "none", borderRadius: "0", fontSize: "12px"}}>
              Все
            </Button>
            {categories?.map((category) => (
              <Button onClick={() => handleCategoryClick(category)} key={category.ID} sx={{color: theme.palette.text.black, borderBottom: category.ID === selectedCategoryId ? `1px solid ${theme.palette.accent.main}`: "none", borderRadius: "0", fontSize: "12px"}}>
                {category.name_ru}
              </Button>
            ))}
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {loading ?
          <CircularProgress />
          : ads &&
          <RecommendationsGrid recommendations={ads} />
        }
      </Grid>
    </Grid>
  );
}

export default Recommendations;
