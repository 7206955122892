import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Breadcrumbs, TextField, MenuItem, Select, FormControl, ListSubheader } from '@mui/material';
import YandexMap from '../maps/YandexMap';
import { getRegions } from '../../api/regions';
import { useTranslation } from 'react-i18next';

const AddressStep = ({ formData, setFormData }) => {
  const { i18n } = useTranslation();
  const [regions, setRegions] = useState([]);
  const [subRegions, setSubRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [street, setStreet] = useState('');
  const [houseNum, setHouseNum] = useState('');
  const [selectedSubRegion, setSelectedSubRegion] = useState('');
  // const [language, setLanguage] = useState(localStorage.getItem('language') || 'ru');
  const language = i18n.language;
  const [address, setAddress] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false); // добавляем состояние для отслеживания загрузки данных

  useEffect(() => {
    setFormData({
      ...formData,
      // address: address, 
      region_id: selectedSubRegion ? selectedSubRegion : selectedRegion,
      street: street,
      houseNum: houseNum
    })
  }, [selectedRegion, selectedSubRegion, street, houseNum])

  useEffect(() => {
    const fetchInitialRegions = async () => {
      await fetchRegions(0);
      setIsDataLoaded(true); // данные загружены
    };
    fetchInitialRegions();
  }, [language]);

  const fetchRegions = async (parentId) => {
    try {
      const data = await getRegions(parentId, language);
      if (parentId === 0) {
        setRegions(data);
      } else {
        setSubRegions(data);
      }
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  };

  const handleRegionChange = (event) => {
    const regionId = event.target.value;
    setSelectedRegion(regionId);
    fetchRegions(regionId);
    setSelectedSubRegion('');
  };

  const handleSubRegionChange = (event) => {
    setSelectedSubRegion(event.target.value);
  };

  const getRegionName = (id, list) => {
    const region = list.find(item => item.id === id);
    return region ? region.name : '';
  };

  useEffect(() => {
    if (selectedRegion) {
      const regionName = getRegionName(selectedRegion, regions);
      const subRegionName = getRegionName(selectedSubRegion, subRegions);
      setAddress(`${regionName}, ${subRegionName}, ${street} д ${houseNum}`);
    } else {
      setAddress("г. Алматы");
    }
  }, [selectedRegion, selectedSubRegion, street, houseNum, regions, subRegions]);



  const normalizeName = (name) => {
    return name.replace(/^г\./, '').replace(/Г\.А\./, '').replace(/район /, '').trim();
  };

  const cities = regions.filter(region => region.name.includes('г.')).sort((a, b) => normalizeName(a.name).localeCompare(normalizeName(b.name)));
  const oblasts = regions.filter(region => region.name.includes('область')).sort((a, b) => normalizeName(a.name).localeCompare(normalizeName(b.name)));

  return (
    <Box>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography variant="h6">{formData.categoryName}</Typography>
        <Typography variant="h6">{formData.subcategoryName}</Typography>
        {formData.subSubcategoryName && <Typography variant="h6">{formData.subSubcategoryName}</Typography>}
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <Select
                  labelId="region-select-label"
                  id="region-select"
                  value={selectedRegion}
                  onChange={handleRegionChange}
                  style={{ maxHeight: '400px' }}
                >
                  <MenuItem value="">----</MenuItem>
                  {/* {regions?.map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))} */}
                  <ListSubheader>Города</ListSubheader>
                  {cities?.map((city) => (
                    <MenuItem key={city.id} value={city.id}>
                      {normalizeName(city.name)}
                    </MenuItem>
                  ))}
                  <ListSubheader>Области</ListSubheader>
                  {oblasts?.map((oblast) => (
                    <MenuItem key={oblast.id} value={oblast.id}>
                      {normalizeName(oblast.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              {subRegions.length > 0 && (
                <FormControl fullWidth>
                  <Select
                    labelId="sub-region-select-label"
                    id="sub-region-select"
                    value={selectedSubRegion}
                    onChange={handleSubRegionChange}
                    style={{ maxHeight: '400px' }}
                  >
                    <MenuItem value="">----</MenuItem>
                    {subRegions?.map((subRegion) => (
                      <MenuItem key={subRegion.id} value={subRegion.id}>
                        {subRegion.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                fullWidth
                label="Улица или микрорайон"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="№ дома"
                value={houseNum}
                onChange={(e) => setHouseNum(e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
        {isDataLoaded && ( // рендерим карту только после загрузки данных
          <Grid item xs={12}>
            <YandexMap address={address} formData={formData} setFormData={setFormData} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AddressStep;
