import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Card, Avatar, Box, Typography, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../config/slices/userSlice';
import { fetchChats, fetchMessages } from '../../../api/chat';
import { getUserById } from '../../../api/auth';
import ChatItem from './ChatItem';  // Adjust the import path as necessary

const Chats = () => {
  const userInfo = useSelector(selectUserInfo);
  const [chats, setChats] = useState([]);
  const [users, setUsers] = useState({});
  const [lastMessages, setLastMessages] = useState({});
  const [selectedChat, setSelectedChat] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const chatsResponse = await fetchChats();
      setChats(chatsResponse);

      const usersData = {};
      const messagesData = {};

      for (const chat of chatsResponse) {
        const userId = chat.author_id === userInfo.id ? chat.buyer_id : chat.author_id;
        if (!usersData[userId]) {
          const userResponse = await getUserById(userId);
          usersData[userId] = userResponse;
        }

        const messagesResponse = await fetchMessages(chat.ID);
        if (messagesResponse.length > 0) {
          messagesData[chat.ID] = messagesResponse[messagesResponse.length - 1].content;
        }
      }

      setUsers(usersData);
      setLastMessages(messagesData);
    };

    fetchData();
  }, [userInfo.id]);

  const getUserDisplayName = (user) => {
    if (!user) return '';
    if (user.first_name || user.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }
    return user.phone_number;
  };

  const getInitials = (firstName, lastName) => {
    return `${lastName?.charAt(0) || ''}${firstName?.charAt(0) || ''}`.toUpperCase();
  };

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="h4" gutterBottom sx={{pl: "16px"}}>Чаты</Typography>
        <List>
          {chats?.map(chat => {
            const userId = chat.author_id === userInfo.id ? chat.buyer_id : chat.author_id;
            const user = users[userId];
            return (
              <ListItem key={chat.ID} onClick={() => handleChatClick(chat)} sx={{ marginBottom: 2, cursor: 'pointer' }}>
                <Card sx={{ display: 'flex', alignItems: 'center', padding: 2, width: '100%' }}>
                  {user?.image_url ? (
                    <Avatar src={user.image_url} sx={{ marginRight: 2 }} />
                  ) : (
                    <Avatar sx={{ marginRight: 2, bgcolor: '#3f51b5' }}>
                      {getInitials(user?.first_name, user?.last_name)}
                    </Avatar>
                  )}
                  <Box>
                    <Typography variant="subtitle1">{getUserDisplayName(user)}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {lastMessages[chat.ID] || 'No messages yet'}
                    </Typography>
                  </Box>
                </Card>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      <Grid item xs={12} md={8}>
        {selectedChat && <ChatItem chat={selectedChat} />}
      </Grid>
    </Grid>
  );
};

export default Chats;
