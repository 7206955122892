import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import Sidebar from '../modules/profile/sidebar/Sidebar';
import Settings from '../modules/profile/settings/Settings';
import Ads from '../modules/profile/ads/Ads';
import Chats from '../modules/profile/chats/Chats';

const ProfilePage = () => {
    const [selectedTab, setSelectedTab] = useState('Настройки');
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Определите состояние selectedTab на основе текущего URL
        if (location.pathname.includes('/chats')) {
            setSelectedTab('Сообщения');
        } else if (location.pathname.includes('/ads')) {
            setSelectedTab('Объявления');
        } else {
            setSelectedTab('Настройки');
        }
    }, [location.pathname]);

    useEffect(() => {
        if (selectedTab==="Выйти") {
            localStorage.clear()
            navigate("/")
        }
    }, [selectedTab, navigate])

    return (
        <Container maxWidth="lg" sx={{
            m: "20px auto",
            p: "10px 0",
        }}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Sidebar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
                </Grid>
                <Grid item xs={9}>
                    {selectedTab === 'Настройки' && <Settings />}
                    {selectedTab === 'Объявления' && <Ads />}
                    {selectedTab === 'Сообщения' && <Chats />}
                </Grid>
            </Grid>
        </Container>
    );
}

export default ProfilePage;
