export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("ru-RU", options); // 'ru-RU' for Russian
};

export const formatNumber = (text) => {
  const number = parseFloat(text);
  if (isNaN(number)) {
    return "Некорректное число";
  }

  return number.toLocaleString("ru-RU").replace(/,/g, " ");
};
