// import React, { useEffect, useState } from 'react';
// import { getCategories } from '../../api/categories';
// import { Box, Grid, Alert, Link, Chip, Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';

// const CategotyList = ({ theme, formData, setFormData }) => {

//     const [categories, setCategories] = useState([]);
//     const [error, setError] = useState('');
//     const [selectedCategoryId, setSelectedCategoryId] = useState(null);

//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const data = await getCategories();
//                 setCategories(data);
//             } catch (err) {
//                 setError('Ошибка при загрузке категорий');
//             }
//         };
//         fetchCategories();
//     }, []);

//     const handleCategoryClick = async (category) => {
//         setSelectedCategoryId(category.ID);
//         setFormData({ ...formData, categoryId: category.ID });
//     };


//     const formatImageUrl = (url) => {
//         if (!url) return '';
//         return `http://localhost:8080/${url.replace(/\\/g, '/')}`;
//     };

//     return (
//         <Box sx={{ width: '100%' }}>
//             <Grid container spacing={2} >
//                 {categories.map((category) => (
//                     <Grid item key={category.ID}>
//                         <Card onClick={() => handleCategoryClick(category)} sx={{
//                             transform: selectedCategoryId === category.ID ? 'scale(1.05)' : 'scale(1)',
//                             border: selectedCategoryId === category.ID ? `1px solid ${theme.palette.secondary.main}` : '1px solid transparent',
//                             transition: 'transform 0.2s, border 0.2s',
//                         }}>
//                             <CardActionArea>
//                                 <CardMedia
//                                     component="img"
//                                     alt={category.name_ru}
//                                     height="140"
//                                     image={formatImageUrl(category.image_url)}
//                                     title={category.name_ru}
//                                 />
//                                 <CardContent>
//                                     <Typography variant="body1" component="div" align="center">
//                                         {category.name_ru}
//                                     </Typography>
//                                 </CardContent>
//                             </CardActionArea>
//                         </Card>
//                     </Grid>
//                 ))}
//             </Grid>
//             {error && (
//                 <Alert severity="error">{error}</Alert>
//             )}
//         </Box>
//     );

// }

// export default CategotyList;
// CategotyList.jsx
import React, { useEffect, useState } from 'react';
import { getCategories } from '../../api/categories';
import { Box, Grid, Alert, Card, CardActionArea, CardMedia, CardContent, Typography } from '@mui/material';

const CategotyList = ({ theme, formData }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState(formData.categoryId || null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories();
                setCategories(data);
            } catch (err) {
                setError('Ошибка при загрузке категорий');
            }
        };
        fetchCategories();
    }, []);

    const handleCategoryClick = (category) => {
        setSelectedCategoryId(category.ID);
        // setFormData({ categoryId: category.ID });
    };

    const formatImageUrl = (url) => {
        if (!url) return '';
        return `${API_URL}/${url.replace(/\\/g, '/')}`;
    };

    return (
        <>
            <Grid container spacing={2} justifyContent="center" sx={{ mt: 2, mb: 1, alignItems: "center",  }}>
                <Grid item xs={12}>
                    <Typography variant="h6" textAlign="center">Категории</Typography>
                </Grid>
                {categories?.map((category) => (
                    <Grid item key={category.ID}>
                        <Card onClick={() => handleCategoryClick(category)} variant="outlined" sx={{
                            border: "none",
                        }}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    alt={category.name_ru}
                                    height="140"
                                    image={formatImageUrl(category.image_url)}
                                    title={category.name_ru}
                                    sx={{ borderRadius: '50%', border: parseInt(selectedCategoryId) === category.ID ? `1px solid ${theme.palette.secondary.main}` : '1px solid transparent', }}
                                />
                                <CardContent>
                                    <Typography variant="body1" component="div" align="center">
                                        {category.name_ru}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {
                error && (
                    <Alert severity="error">{error}</Alert>
                )
            }
        </>
    );
}

export default CategotyList;
