import axiosInstance from './axios';

export const checkPhoneNumber = async (phone) => {
    return await axiosInstance.post('/checkPhone', { phone_number: phone });
};

export const sendCode = async (phone) => {
    return await axiosInstance.post('/sendCode', { phone_number: phone });
};

export const verifyCode = async (phone, code) => {
    return await axiosInstance.post('/verify', { phone_number: phone, code });
};

export const createPassword = async (phone, password) => {
    return await axiosInstance.post('/create-password', { phone_number: phone, password });
};

export const login = async (phone, password) => {
    return await axiosInstance.post('/login', { phone_number: phone, password });
};

export const reactivateAccount = async (phone, code) => {
    return await axiosInstance.post('/reactivateAccount', { phone_number: phone, code });
};

export const getUserById = async (userId) => {
    const response = await axiosInstance.get(`/users/${userId}`);
    return response.data
};
