import React, { useState, useEffect } from 'react';
import { YMaps, Map, Placemark, Clusterer } from '@pbe/react-yandex-maps';
import { Box, Grid, Card, CardMedia, CardContent, Typography, Button, List, ListItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { ru, kk } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const MapComponent = ({ data }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const { i18n } = useTranslation();
    const navigate = useNavigate()
    const theme = useTheme();
    const [selectedPlace, setSelectedPlace] = useState(null);

    const handlePlacemarkClick = (item) => {
        setSelectedPlace(item);
    };

    const formatDate = (isoString) => {
        const currentLocale = i18n.language === 'kz' ? kk : ru;
        return format(new Date(isoString), 'dd MMMM yyyy HH:mm:ss', { locale: currentLocale });
    };


    const formatImageUrl = (url) => {
        if (!url) return '';
        return `${API_URL}/${url.replace(/\\/g, '/')}`;
    };

    const handleClick = (id) => {
      navigate(`/detailsAd/${id}`);
    };

    return (
        <YMaps>
            <Box sx={{ display: 'flex', width: "100%" }}>
                <Map
                    defaultState={{ center: [data[0]?.latitude, data[0]?.longitude], zoom: 14 }}
                    width="70%"
                    height="600px"
                    onClick={() => setSelectedPlace(null)}
                >
                    <Clusterer
                        options={{
                            preset: 'islands#invertedVioletClusterIcons',
                            groupByCoordinates: false,
                        }}
                    >
                        {data?.map((item) => (
                            <Placemark
                                key={item.id}
                                geometry={[item.latitude, item.longitude]}
                                onClick={() => handlePlacemarkClick(item)}
                                properties={{
                                    balloonContentHeader: item.price,
                                    balloonContentBody: `
                                        <div>
                                            <p>${item.address}</p>
                                            <p>${formatDate(item.CreatedAt)}</p>
                                        </div>
                                    `,
                                }}
                                options={{
                                    balloonCloseButton: true,
                                    hideIconOnBalloonOpen: false,
                                }}
                                modules={['geoObject.addon.balloon']}
                            />
                        ))}
                    </Clusterer>
                </Map>
                <Box sx={{ width: '30%', height: '600px', overflowY: 'auto', padding: '10px' }}>
                    {selectedPlace ? (
                        <Card onClick={() => handleClick(selectedPlace.ID)} sx={{
                            transform:  'scale(1.05)',
                            border: `1px solid ${theme.palette.secondary.main}`,
                            transition: 'transform 0.2s, border 0.2s',
                          }}>
                            <CardMedia
                                component="img"
                                alt={selectedPlace.name_ru}
                                height="140"
                                image={formatImageUrl(selectedPlace?.images[0].url)}
                                title={selectedPlace.name_ru}
                            />
                            <CardContent>
                                <Typography variant="h6">{selectedPlace.price} тг</Typography>
                                <Typography>{selectedPlace.address}</Typography>
                                <Typography>{formatDate(selectedPlace.CreatedAt)}</Typography>
                                <Typography>{selectedPlace.views}</Typography>
                            </CardContent>
                        </Card>
                    ) : (
                        <Grid container spacing={2}>
                            {data.slice(0, data.length)?.map((item) => (
                                <Grid item xs={12} key={item.id}>
                                    <Card onClick={() => setSelectedPlace(item)}>
                                        <CardMedia
                                            component="img"
                                            alt={item.name_ru}
                                            height="140"
                                            image={formatImageUrl(item?.images[0].url)}
                                            title={item.name_ru}
                                        />
                                        <CardContent>
                                            <Typography variant="h6">{item.price} тг</Typography>
                                            <Typography>{item.title}</Typography>
                                            <Typography>{item.address}</Typography>
                                            <Typography>{formatDate(item.CreatedAt)}</Typography>
                                            <Typography>{item.views}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Box>
            </Box>
        </YMaps>
    );
};

export default MapComponent;
