// src/RecommendationCard.js
import { Card, CardMedia, CardContent, Typography, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getCategoryByID } from '../../api/categories';
import { useEffect, useState } from 'react';
import { formatNumber } from '../../utils';

const RecommendationCard = ({ item }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [categoryName, setCategoryName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getCategoryByID(item.category_id);
      setCategoryName(data.name_ru);
    };

    fetchData();
  }, [item]);

  const formatImageUrl = (url) => {
    if (!url) return '';
    return `${API_URL}/${url.replace(/\\/g, '/')}`;
  };

  const handleClick = () => {
    navigate(`/detailsAd/${item.ID}`);
  };

  const tooltipText = `${categoryName}+ ${item.price}+ ${item.region.NameRus} `

  return (
    <Tooltip title={
      <>
        <Typography variant="body2">Категория: {categoryName}</Typography>
        <Typography variant="body2">Цена: {formatNumber(item.price)}</Typography>
        <Typography variant="body2">Регион: {item.region.NameRus}</Typography>
      </>
    }
      arrow 
      followCursor
    >
      <Card onClick={handleClick} sx={{ width: "200px" }}>
        <CardMedia
          component="img"
          height="140"
          image={formatImageUrl(item.images[0].url)}
          alt={categoryName}
        />
        <CardContent>
          <Typography variant="subtitle1">{categoryName}</Typography>
          <Typography variant="subtitle2" >{formatNumber(item.price)}</Typography>
          {/* <Typography variant="body1">{item.description}</Typography> */}
        </CardContent>
      </Card>
    </Tooltip>
  );
};

export default RecommendationCard;
