const initialState = {
  chats: [],
  messages: {},
};

export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_CHATS_SUCCESS':
      return { ...state, chats: action.payload };
    case 'FETCH_MESSAGES_SUCCESS': {
      const chatId = action.payload[0].chat_id;
      return { 
        ...state, 
        messages: { 
          ...state.messages, 
          [chatId]: action.payload 
        } 
      };
    }
    case 'SEND_MESSAGE_SUCCESS':
      const chatId = action.payload.chat_id;
      return {
        ...state,
        messages: {
          ...state.messages,
          [chatId]: [...(state.messages[chatId] || []), action.payload],
        },
      };
    case 'RECEIVE_MESSAGE':
      const receivedChatId = action.payload.chat_id;
      return {
        ...state,
        messages: {
          ...state.messages,
          [receivedChatId]: [...(state.messages[receivedChatId] || []), action.payload],
        },
      };
    default:
      return state;
  }
}
