import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getRegions } from '../../api/regions';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Grid, Select, MenuItem, FormControl, ListSubheader, } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MapComponent from './MapComponent';
import { getAds } from '../../api/ads';
import { setFilters } from '../../config/slices/filterSlice';
import queryString from 'query-string';
import FilterProducts from './FilterProducts';

const FindProduct = ({ theme }) => {

    const { i18n } = useTranslation();
    const language = i18n.language;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formData = useSelector((state) => state.filter);
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('all');
    const [keyword, setKeyword] = useState('');
    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openMapComponent, setOpenMapComponent] = useState(false);
    const [showFilterProducts, setShowFilterProducts] = useState(false);



    const handleFormDataChange = (newFormData) => {
        dispatch(setFilters(newFormData));

        const nonEmptyParams = Object.keys(newFormData)
            .filter(key => newFormData[key])
            .reduce((obj, key) => {
                obj[key] = newFormData[key];
                return obj;
            }, {});

        const queryStringParams = queryString.stringify(nonEmptyParams);
        navigate(`/?${queryStringParams}`);
    };

    useEffect(() => {
        const fetchInitialRegions = async () => {
            await fetchRegions(0);
        };
        fetchInitialRegions();
    }, [language]);

    const fetchRegions = async (parentId) => {
        try {
            const data = await getRegions(parentId, language);
            if (parentId === 0) {
                setRegions(data);
            }
        } catch (error) {
            console.error('Error fetching regions:', error);
        }
    };

    const handleRegionChange = (event) => {
        const regionId = event.target.value;
        setSelectedRegion(regionId);
        fetchRegions(regionId);
        handleFormDataChange({ ...formData, regionId });
    };

    const handleFindClick = () => {
        handleFormDataChange({ ...formData, keyword });
        setShowFilterProducts(true);
    };

    const handleInputChange = (event) => {
        setKeyword(event.target.value);
    };

    const normalizeName = (name) => {
        return name.replace(/^г\./, '').replace(/Г\.А\./, '').replace(/район /, '').trim();
    };

    const cities = regions.filter(region => region.name.includes('г.')).sort((a, b) => normalizeName(a.name).localeCompare(normalizeName(b.name)));
    const oblasts = regions.filter(region => region.name.includes('область')).sort((a, b) => normalizeName(a.name).localeCompare(normalizeName(b.name)));



    const fetchAds = async (params) => {
        try {
            setLoading(true);
            const data = await getAds(params);
            setAds(data);
        } catch (error) {
            console.error('Error fetching ads:', error);
            if (error.response) {
                console.error('Server response:', error.response.data);
                console.error('Server status:', error.response.status);
                console.error('Server headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Request setup error:', error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const params = queryString.parse(location.search);
        dispatch(setFilters({
            categoryId: params.categoryId || '',
            subcategoryId: params.subcategoryId || '',
            subSubcategoryId: params.subSubcategoryId || '',
            priceFrom: params.priceFrom || '',
            priceTo: params.priceTo || '',
            keyword: params.keyword || '',
            regionId: params.regionId || ''
        }));
    }, [location.search, dispatch]);

    useEffect(() => {
        const params = {
            // category_id: formData.subSubcategoryId || formData.subcategoryId || formData.categoryId,
            // price_from: formData.priceFrom,
            // price_to: formData.priceTo,
            keyword: formData.keyword,
            region_id: formData.regionId
        };
        fetchAds(params);
    }, [formData]);

    const handleMapClick = () => {
        setOpenMapComponent(!openMapComponent)
    }

    return (
        <Grid container spacing={2} sx={{ mt: 1, mb: 1, alignItems: "center" }}>
            <Grid item >
                <Box display="flex" alignItems="center" gap={2}>
                    <Paper
                        component="form"
                        sx={{ display: 'flex', alignItems: 'center', width: 400, borderRadius: "25px", paddingLeft: "7px" }}
                    >
                        <IconButton sx={{ p: '10px' }} aria-label="menu">
                            <SearchIcon />
                        </IconButton>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Что-то ищете?"
                            inputProps={{ 'aria-label': 'Что-то ищете?' }}
                            value={keyword}
                            onChange={handleInputChange}
                        />
                        <Button
                            variant="contained"
                            onClick={handleFindClick}
                            sx={{
                                borderRadius: "25px",
                                backgroundColor: 'limegreen',
                                p: '15px',
                                boxShadow: "none",
                                color: theme.palette.text.primary
                            }}
                        >
                            Поиск
                        </Button>
                    </Paper>
                    <Button
                        variant="contained"
                        startIcon={<LocationOnOutlinedIcon />}
                        sx={{ borderRadius: '25px', textTransform: 'none', p: "15px 25px", background: "transparent", color: theme.palette.text.grey }}
                        onClick={handleMapClick}
                    >
                        На карте
                    </Button>

                    <FormControl size="small" sx={{ minWidth: 240 }}>
                        <Select
                            value={selectedRegion}
                            onChange={handleRegionChange}
                            renderValue={(selected) => {
                                if (selected === 'all') return 'Весь Казахстан';
                                const selectedRegion = regions.find(region => region.id === selected);
                                return selectedRegion ? normalizeName(selectedRegion.name) : '';
                            }}
                            sx={{
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                        >
                            <MenuItem value="all">
                                Весь Казахстан
                            </MenuItem>
                            <ListSubheader>Города</ListSubheader>
                            {cities?.map((city) => (
                                <MenuItem key={city.id} value={city.id}>
                                    {normalizeName(city.name)}
                                </MenuItem>
                            ))}
                            <ListSubheader>Области</ListSubheader>
                            {oblasts?.map((oblast) => (
                                <MenuItem key={oblast.id} value={oblast.id}>
                                    {normalizeName(oblast.name)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            {openMapComponent ?
                <Grid item xs={12} sm={2} md={12} lg={12}>
                    <MapComponent data={ads} />
                </Grid>
                :
                showFilterProducts &&
                <Grid item xs={12} sm={2} md={12} lg={12}>
                    <FilterProducts data={ads} />
                </Grid>
            }
        </Grid>
    );
}

export default FindProduct;
