import React, { useEffect, useState } from 'react';
import { getSubcategories } from '../../api/categories';
import { Box, Alert, List, ListItem, ListItemText, Paper, Typography, Grid, Breadcrumbs } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SubcategoryStep = ({ formData, setFormData }) => {
  const { i18n } = useTranslation();
  const [subcategories, setSubcategories] = useState([]);
  const [subSubcategories, setSubSubcategories] = useState([]);
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);
  const [selectedSubSubcategoryId, setSelectedSubSubcategoryId] = useState(null);
  const [error, setError] = useState('');
  const language = i18n.language;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getSubcategories(formData.categoryId);
        setSubcategories(data);
      } catch (err) {
        setError('Ошибка при загрузке подкатегорий');
      }
    };
    fetchCategories();
  }, [formData.categoryId]);

  const handleSubcategoryClick = async (subcategory) => {
    setSelectedSubcategoryId(subcategory.ID);

    try {
      const data = await getSubcategories(subcategory.ID);
      if (data.length > 0) {
        setSubSubcategories(data);
        setFormData(prevData => ({
          ...prevData,
          nestedSubcategories: true,
          subcategoryId: subcategory.ID,
          subcategoryName: language === "ru" ? subcategory.name_ru : subcategory.name_kz
        }));
      } else {
        setSubSubcategories([]);
        setFormData(prevData => ({
          ...prevData,
          subcategoryId: subcategory.ID,
          subcategoryName: language === "ru" ? subcategory.name_ru : subcategory.name_kz
        }));
      }
    } catch (err) {
      setError('Ошибка при загрузке подкатегорий');
    }
  };

  const handleSubSubcategoryClick = async (subcategory) => {
    setSelectedSubSubcategoryId(subcategory.ID);

    try {
      setFormData(prevData => ({
        ...prevData,
        subSubcategoryId: subcategory.ID,
        subSubcategoryName: language === "ru" ? subcategory.name_ru : subcategory.name_kz
      }));
    } catch (err) {
      setError('Ошибка при загрузке подкатегорий');
    }
  };

  return (
    <Box>
      <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography variant="h6">{formData.categoryName}</Typography>
      </Breadcrumbs>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper sx={{ bgcolor: 'background.paper' }}>
            <List>
              {subcategories?.map((subcategory) => (
                <ListItem
                  key={subcategory.ID}
                  button
                  onClick={() => handleSubcategoryClick(subcategory)}
                  selected={selectedSubcategoryId === subcategory.ID}
                >
                  <ListItemText primary={subcategory.name_ru} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        {subSubcategories.length > 0 && (
          <Grid item xs={4}>
            <Paper sx={{ bgcolor: 'background.paper' }}>
              <List>
                {subSubcategories?.map((sub) => (
                  <ListItem
                    key={sub.ID}
                    button
                    onClick={() => handleSubSubcategoryClick(sub)}
                    selected={selectedSubSubcategoryId === sub.ID}
                  >
                    <ListItemText primary={sub.name_ru} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        )}
        {error && (
          <Grid item xs={12}>
            <Alert severity="error">{error}</Alert>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SubcategoryStep;
