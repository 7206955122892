import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

const Sidebar = ({ selectedTab, setSelectedTab }) => {
    const tabs = ['Объявления', 'Сообщения', 'Настройки', 'Выйти'];

    return (
        <List component="nav" sx={{
            borderRadius: "5px",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,0.15)",
        }}>
            {tabs?.map(tab => (
                <ListItem
                    button
                    key={tab}
                    selected={selectedTab === tab}
                    onClick={() => setSelectedTab(tab)}
                >
                    <ListItemText primary={tab} />
                </ListItem>
            ))}
        </List>
    );
};

export default Sidebar;
