import React from 'react';
import { Container, Box, TextField, Button, Typography, Link, FormControlLabel, Checkbox, Stack, Alert } from '@mui/material';
import AdForm from '../components/AdForm/AdForm';

const AdPage = () => {
  return (
    <Container
      sx={{
        justifyContent: 'center',
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        p: "100px 0"
      }}
    >
      <AdForm />
    </Container>
  );
};

export default AdPage;
