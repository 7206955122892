import React, { useState } from 'react';
import { Typography, Button, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { deactivateAcc } from '../../../api/contactChange';

const DeleteAccount = () => {
    const navigate = useNavigate();

    const handleDeactivate = async () => {
        try {
            const response = await deactivateAcc();
            if (response.status === 200) {
                alert(response.data.message);
                localStorage.clear();
                navigate('/');
            }
        } catch (error) {
            console.error('Error deactivating account:', error);
            alert('Ошибка при деактивации аккаунта');
        }
    };

    return (
        <Box sx={{
            m: "20px auto",
            p: "30px",
            borderRadius: "5px",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,0.15)",
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Typography > Вы уверены? </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                fullWidth
                                onClick={handleDeactivate}
                            >
                                Да
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                fullWidth
                                // onClick={() => history.goBack()}
                            >
                                Нет
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DeleteAccount;
