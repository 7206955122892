import React, { useState } from 'react';
import CategoryStep from './CategoryStep';
import DetailsStep from './DetailsStep';
import ImagesStep from './ImagesStep';
import StepNavigation from './StepNavigation';
import { createAd, uploadAdImage } from '../../api/ads';
import { Stepper, Step, StepLabel, Stack, Box, Button, StepConnector, stepConnectorClasses, styled, Alert, Snackbar } from '@mui/material';
import SubcategoryStep from './SubcategoryStep';
import AddressStep from './AddressStep';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Check from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';

const CustomStepIcon = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active || ownerState.completed ? theme.palette.secondary.main : theme.palette.background.default,
  zIndex: 1,
  color: '#fff',
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,0.25)",
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
  }),
  boxShadow: "0 4px 10px 0 rgba(0,0,0,0.25)",
  border: ownerState.active || ownerState.completed ? "1px solid #fff" : `1px solid ${theme.palette.secondary.main}`
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 6,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 12,
    border: 0,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 1,
    width: "120%",
    left: "-20px",
    position: "absolute"
  },
}));

const AdForm = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    categoryId: '',
    subcategoryId: '',
    nestedSubcategories: false,
    subSubcategoryId: '',
    price: 0,
    description: '',
    address: '',
    images: [],
    categoryName: '',
    subcategoryName: '',
    subSubcategoryName: '',
    latitude: 0,
    longitude: 0,
    region_id: '',
    street: '',
    houseNum: ''
  });

  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [snackbarText, setSnackbarText] = useState('')
  const { vertical, horizontal, open } = snackbarOpen;

  const [error, setError] = useState('');

  const steps = ['Выбор категории', 'Подкатегория', 'Описание/Стоимость', 'Фото', 'Адрес'];

  const handleNext = () => {
    if (validateStep()) {
      setStep(step + 1);
      setError('');
    } else {
      setError('Пожалуйста, заполните все необходимые поля');
    }
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
    setError('');
    switch (step) {
      case 1:
        return setFormData({ ...formData, categoryId: '', categoryName: '' });
      case 2:
        return setFormData({ ...formData, subcategoryId: '', nestedSubcategories: false, subSubcategoryId: '', subcategoryName: '', subSubcategoryName: '', });
      case 3:
        return setFormData({ ...formData, description: '', price: 0 });
      case 4:
        return setFormData({ ...formData, images: [] });
      default:
        return false;
    }
  };

  const validateStep = () => {
    switch (step) {
      case 0:
        return formData.categoryId !== '';
      case 1:
        return formData.subcategoryId !== '' || (formData.nestedSubcategories && formData.subSubcategoryId !== '');
      case 2:
        return formData.description !== '' && formData.price > 0;
      case 3:
        return formData.images.length > 0;
      case 4:
        return formData.region_id !== '' && formData.street && formData.houseNum;
      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    try {
      if (validateStep()) {
        let category_id = formData.categoryId;
        if (formData.subSubcategoryId) {
          category_id = formData.subSubcategoryId;
        } else if (formData.subcategoryId) {
          category_id = formData.subcategoryId;
        }
        
        const data = {
          latitude: formData.latitude,
          longitude: formData.longitude,
          description: formData.description,
          price: formData.price,
          address: formData.address,
          category_id: category_id,
          region_id: formData.region_id,
          street: formData.street,
          houseNum: formData.houseNum
        }
        const ad = await createAd(data);

        for (const image of formData.images) {
          try {
            await uploadAdImage(ad.ID, image.file);
            setSnackbarText('Объявление успешно добавлено')
            setSnackbarOpen({ open: true });
            navigate("/profile/pending")
          } catch (error) {
            console.error("Ошибка при загрузке изображения:", error);
          }
        }
      } else {
        setError('Пожалуйста, заполните все необходимые поля');
      }
    } catch (error) {
      console.error(error);
      alert('Произошла ошибка при добавлении объявления');
    }
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Box>
        <Stepper activeStep={step} alternativeLabel connector={<CustomConnector />}>
          {steps?.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon ownerState={props}>
                    {props.completed ? <Check sx={{ fontSize: 16 }} /> : null}
                  </CustomStepIcon>
                )}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box>
        {step === 0 && <CategoryStep formData={formData} setFormData={setFormData} />}
        {step === 1 && <SubcategoryStep formData={formData} setFormData={setFormData} />}
        {step === 2 && <DetailsStep formData={formData} setFormData={setFormData} />}
        {step === 3 && <ImagesStep formData={formData} setFormData={setFormData} />}
        {step === 4 && <AddressStep formData={formData} setFormData={setFormData} />}
      </Box>
      {error && (
        <Alert severity="error">{error}</Alert>
      )}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={1000}
        message={snackbarText}
        key={vertical + horizontal}
      />
      <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          disabled={step === 0}
          onClick={handleBack}
        >
          Назад
        </Button>
        {step < 4 ? (
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={handleNext}
          >
            Далее
          </Button>
        ) : (
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            onClick={handleSubmit}
          >
            Завершить
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default AdForm;
