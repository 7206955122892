import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
console.log(process.env)
const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        let refresh_token = ""
        const value = `; ${document.cookie}`;
        const parts = value.split(`; refresh_token=`);
        if (parts.length === 2) refresh_token = parts.pop().split(";").shift();

        const response = await axios.post(`${API_URL}/refresh`, {
          refresh_token,
        });

        const { access_token } = response.data;

        localStorage.setItem("access_token", access_token);

        originalRequest.headers.Authorization = `Bearer ${access_token}`;

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
