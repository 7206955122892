import React, { useEffect, useState } from 'react';
import { getCategories } from '../../api/categories';
import { Box, Typography, Grid, Card, CardActionArea, CardContent, CardMedia, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const CategoryStep = ({ formData, setFormData }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const theme = useTheme();

  const language = i18n.language;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories();
        const sortedData = data.sort((a, b) => a.ID - b.ID);
        setCategories(sortedData);
      } catch (err) {
        setError('Ошибка при загрузке категорий');
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryClick = async (category) => {
    setSelectedCategoryId(category.ID);
    setFormData({ ...formData, categoryId: category.ID, categoryName: language === "ru" ? category.name_ru : category.name_kz });
  };

  const formatImageUrl = (url) => {
    if (!url) return '';
    return `${API_URL}/${url.replace(/\\/g, '/')}`;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* {categories?.map((category) => (
          <Grid item xs={12} sm={6} md={4} lg={2} key={category.ID}>
            <Card onClick={() => handleCategoryClick(category)} sx={{
              transform: selectedCategoryId === category.ID ? 'scale(1.05)' : 'scale(1)',
              border: selectedCategoryId === category.ID ? `1px solid ${theme.palette.secondary.main}` : '1px solid transparent',
              transition: 'transform 0.2s, border 0.2s',
            }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={category.name_ru}
                  height="140"
                  image={formatImageUrl(category.image_url)}
                  title={category.name_ru}
                />
                <CardContent>
                  <Typography variant="h6" component="div" align="center">
                    {category.name_ru}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))} */}
        {categories?.map((category) => (
          <Grid item key={category.ID}>
            <Card onClick={() => handleCategoryClick(category)} variant="outlined" sx={{
              border: "none",
            }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={category.name_ru}
                  height="140"
                  image={formatImageUrl(category.image_url)}
                  title={category.name_ru}
                  sx={{ borderRadius: '50%', border: parseInt(selectedCategoryId) === category.ID ? `1px solid ${theme.palette.secondary.main}` : '1px solid transparent', }}
                />
                <CardContent>
                  <Typography variant="body1" component="div" align="center">
                    {category.name_ru}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {error && (
        <Alert severity="error">{error}</Alert>
      )}
    </Box>
  );
};

export default CategoryStep;
