import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Container maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Политика конфиденциальности
                </Typography>
                <Typography variant="body1" paragraph>
                    Данная политика конфиденциальности относится к сайту под доменным именем www.maltap.kz (далее Сайт) и описывает установленный Администрацией Сайта порядок защиты, обработки и хранения персональных данных, собираемых при подачи объявлений, регистраций и просмотре уже опубликованных объявлений на сайте и мобильных приложениях maltap.kz.
                    Персональные данные пользователей сайта, собираемые при посещении сайта обрабатываются в рамках требований Закона Республики Казахстан «О персональных данных и их защите».
                    Персональные данные
                    Персональные данные при посещении сайта передаются Пользователем добровольно, к ним могут относиться: ФИО или название Компании, ИИН или БИН, номера телефонов, адреса электронной почты, адреса для доставки животных, товаров или оказания услуг, реквизиты компании, которую представляет пользователь, аккаунты в социальных сетях; поля форм могут запрашивать и иные данные.
                    Эти данные собираются в целях оказания услуг или продажи животных и товаров, связи с пользователем или иной активности пользователя на сайте, а также, чтобы отправлять пользователям информацию, которую они согласились получать.
                    Вся личная информация о Пользователе используется только по своему прямому назначению. Используя Сайт, Пользователь соглашается с условиями настоящей Политики конфиденциальности. Если пользователь не согласен с настоящей политикой, ему необходимо покинуть и не использовать сайт.
                    Персональные данные пользователя могут публиковаться в общем доступе в соответствии с функционалом сайта, например, при оставлении комментариев (отзывов), может публиковаться указанное пользователем имя, такая активность на сайте является добровольной, и пользователь своими действиями дает согласие на такую публикацию. В мерах безопасности, пользователи не должны включать дополнительную личную информацию в комментариях и/или сообщениях.
                    Служба безопасности сайта принимает все возможные меры для соблюдения настоящей политики конфиденциальности, но не может на 100 % проверять достоверность подаваемых объявлений, оставляемых данных и не может гарантировать качественного исполнения обратной связи с пользователями при некорректных данных.
                    Администрация сайта принимаем соответствующие меры безопасности по сбору, хранению и обработке собранных данных для защиты их от несанкционированного доступа, изменения, раскрытия или уничтожения, ограничиваем нашим сотрудникам, постоянно совершенствуем способы сбора, хранения и обработки данных, включая физические меры безопасности, для противодействия несанкционированному доступу к системам сайта.
                    Также, данные могут собираться через технологию cookies (куки) как непосредственно сайтом, так и скриптами сервисов сторонних организаций. Эти данные собираются автоматически, отправку этих данных можно запретить, отключив cookies (куки) в браузере, в котором открывается сайт.
                    Не персональные данные
                    Кроме персональных данных при посещении сайта собираются не персональные данные, их сбор происходит автоматически веб-сервером, на котором расположен сайт, средствами CMS (системы управления сайтом), скриптами сторонних организаций, установленными на сайте. К данным, собираемым автоматически, относятся: IP адрес и страна его регистрации, имя домена, с которого вы к нам пришли, переходы посетителей с одной страницы сайта на другую, информация, которую ваш браузер предоставляет добровольно при посещении сайта, cookies (куки), фиксируются посещения, иные данные, собираемые счетчиками аналитики сторонних организаций, установленными на сайте.
                    Эти данные носят неперсонифицированный характер и направлены на улучшение обслуживания клиентов, улучшения удобства использования сайта, анализа посещаемости.
                    Использование данных
                    Для оказания своих услуг Сайт может использовать информацию, которую собирает для улучшения обслуживания пользователей, улучшения удобства использования сайта, анализа посещаемости и для других целей:
                     обеспечение обслуживания пользователей и для создания и управления учетными записями пользователей, решения технических трудностей;
                     адаптация рекламы на сайте или сервисах третьих лиц;
                     контроль активности Пользователей, такой как поиск по ключевым словам, регионам, активности по публикациям объявлений, а также для управления трафиком на Сайте;
                     связь с пользователями сайта для проверки подлинности и достоверности предоставляемой информации, а также по вопросам сервиса, обслуживания пользователей;
                     обеспечение соблюдения Пользовательского соглашения сайта, включая борьбу с мошенничеством и ненормативной лексики, тем самым соблюдение общепринятых этических требований.
                    Сайт будет хранить персональные данные пользователей на срок необходимый для достижения целей, ради которых они собраны, в том числе для выполнения любых других требований, предусмотренных законами Республики Казахстан.
                    Предоставление данных третьим лицам
                    Мы не раскрываем личную информацию пользователей сайта другим организациям, частным лицам и используем ее для обеспечения качественного оказания услуг и их учета.
                    Информация может быть раскрыта в целях воспрепятствования мошенничеству или иным противоправным действиям и в иных случаях, предусмотренных законом.
                    На сайте могут быть установлены формы, собирающие персональную информацию других организаций, в этом случае сбор, хранение и защита персональной информации пользователя осуществляется сторонними организациями в соответствии с их политикой конфиденциальности.
                    При регистрации на сайте пользователь дает согласие на получение от сайта рассылок рекламно-информационного характера (новости, изменения, оповещения о важных событиях, информация об объявлениях и др.).
                    Рассылки поступают в виде уведомлениях через мобильные приложения, электронного письма на адрес и сообщения (SMS) на номер телефона, указанный пользователем при регистрации или подаче объявления.
                    Пользователь можете отписаться от информационных и рекламных рассылок сайта в любое время.
                    Отказ от ответственности
                    Политика конфиденциальности не распространяется ни на какие другие сайты и не применима к веб-сайтам третьих лиц, которые могут содержать упоминание о нашем сайте и с которых могут делаться ссылки на сайт, а также ссылки с этого сайта на другие сайты сети Интернет. Мы не несем ответственности за действия других веб-сайтов. Все животные, товары, описания и цены носят ознакомительный характер и не являются товарным предложением и публичной офертой. Вся ответственность за приобретение данных товаров ложится исключительно на покупателя.
                    Изменения в политике конфиденциальности
                    Руководство сайта имеем право по своему усмотрению обновлять данную политику конфиденциальности в любое время. В этом случае сайт опубликует уведомление на главной странице нашего сайта. Также, рекомендуем пользователям регулярно проверять эту страницу для того, чтобы быть в курсе любых изменений о том, как мы защищаем информацию пользователей. Используя сайт, пользователь соглашается с принятием на себя ответственности за периодическое ознакомление с политикой конфиденциальности и изменениями в ней.
                    Как с нами связаться
                    Если у пользователя есть какие-либо вопросы о политике конфиденциальности, использованию сайта или иным вопросам, связанным с сайтом, свяжитесь с нами
                    kz.maltap@gmail.com
                </Typography>
            </Box>
        </Container>
    );
};

export default PrivacyPolicy;
