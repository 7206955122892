import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#4CAF50",
      light: '#80e27e',
      dark: '#087f23',
      contrastText: "#fff",
    },
    secondary: {
      main: "#5D4037",
      light: '#a28985',
      dark: '#321911',
      contrastText: "#fff",
    },
    accent: {
      main: "#FA8232"
    },
    background: {
      default: "#FBF6EF",
      paper: "#FBF6EF",
      box: "#f2f2f3",
      white: "#fff"
    },
    text: {
      primary: "#1c1819",
      grey: "#9f9f9f",
      black: "#191C1F"
    },
    border: {
      main: "#f2f2f3",
    },
    footer: {
        main: "#f1f1f1"
    }
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#4CAF50",
      light: '#80e27e',
      dark: '#087f23',
      contrastText: "#1c1819",
    },
    secondary: {
      main: "#5D4037",
      light: '#a28985',
      dark: '#321911',
      contrastText: "#1c1819",
    },
    accent: {
      main: "#FA8232"
    },
    background: {
      default: "#1c1819",
      paper: "#1c1819",
      box: "#f2f2f3",
      white: "#fff"
    },
    text: {
      primary: "#fff",
      grey: "#9f9f9f",
      black: "#191C1F"
    },
    border: {
      main: "#f2f2f3",
    },
    footer: {
        main: "#1c1819"
    }
  },
});

export { lightTheme, darkTheme };
