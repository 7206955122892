// import React, { useEffect, useState } from 'react';
// import { Container, Box } from '@mui/material';
// import CategotyList from '../modules/home/CategotyList';
// import Filter from '../modules/home/Filter';
// import { useTheme } from '@mui/material/styles';
// import SubcategotyList from '../modules/home/SubcategotyList';
// import MapComponent from '../modules/home/MapComponent';
// import ButtonComponent from '../modules/home/ButtonComponent';
// import { getAds } from '../api/ads';
// import Recommendations from '../modules/recommendations/Recommendations';

// const HomePage = () => {
//     const theme = useTheme();
//     const [formData, setFormData] = useState({
//         categoryId: '',
//         subcategoryId: '',
//         subSubcategoryId: '',
//         priceFrom: '',
//         priceTo: '',
//         keyword: '',
//         region_id: ''
//     });

//     const [ads, setAds] = useState([]);
//     const [loading, setLoading] = useState(false);

//     const fetchAds = async (params) => {
//         try {
//             setLoading(true);
//             const data = await getAds(params);
//             setAds(data);
//         } catch (error) {
//             console.error('Error fetching ads:', error);
//             if (error.response) {
//                 console.error('Server response:', error.response.data);
//                 console.error('Server status:', error.response.status);
//                 console.error('Server headers:', error.response.headers);
//             } else if (error.request) {
//                 console.error('No response received:', error.request);
//             } else {
//                 console.error('Request setup error:', error.message);
//             }
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         fetchAds({
//             category_id: formData.subSubcategoryId ? formData.subSubcategoryId : (formData.subcategoryId ? formData.subcategoryId : formData.categoryId),
//             price_from: formData.priceFrom,
//             price_to: formData.priceTo,
//             keyword: formData.keyword,
//             region_id: formData.region_id
//         });
//     }, [formData]);

//     const clearFormData = () => {
//         setFormData({
//             ...formData,
//             subcategoryId: '',
//             priceFrom: '',
//             priceTo: '',
//             keyword: '',
//             region_id: ''
//         });
//     }

//     return (
//         <>
//             <Box sx={{
//                 boxShadow: "0 2px 5px 0 rgba(0,0,0,0.15)",
//                 background: theme.palette.background.box,
//             }}>
//                 <Container
//                     sx={{
//                         justifyContent: 'center',
//                         display: "flex",
//                         alignItems: "center",
//                         flexDirection: "column",
//                         m: "20px auto",
//                         p: "10px 0",
//                     }}
//                 >
//                     <CategotyList theme={theme} formData={formData} setFormData={setFormData} />
//                     {formData.categoryId && (
//                         <>
//                             <SubcategotyList theme={theme} formData={formData} setFormData={setFormData} />
//                             <Filter theme={theme} formData={formData} setFormData={setFormData} />
//                             <ButtonComponent theme={theme} clearFormData={clearFormData} />
//                         </>
//                     )}
//                 </Container>
//             </Box>
//             {formData.categoryId && (
//                 <Container
//                     sx={{
//                         justifyContent: 'center',
//                         display: "flex",
//                         alignItems: "center",
//                         flexDirection: "column",
//                         m: "20px auto",
//                         paddingLeft: "0px!important",
//                         paddingRight: "0px!important",
//                         borderRadius: "5px",
//                         boxShadow: "0 2px 3px 0 rgba(0,0,0,0.25)"
//                     }}
//                 >
//                     <MapComponent data={ads} />
//                 </Container>
//             )}
//             <Recommendations />
//         </>
//     );
// }

// export default HomePage;

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import CategotyList from '../modules/home/CategotyList';
import Filter from '../modules/home/Filter';
import { useTheme } from '@mui/material/styles';
import SubcategotyList from '../modules/home/SubcategotyList';
import MapComponent from '../modules/home/MapComponent';
import ButtonComponent from '../modules/home/ButtonComponent';
import { getAds } from '../api/ads';
import Recommendations from '../modules/recommendations/Recommendations';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters, clearFilters } from '../config/slices/filterSlice';
import FindProduct from '../modules/home/FindProduct';

const HomePage = () => {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const formData = useSelector((state) => state.filter);
    const [ads, setAds] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchAds = async (params) => {
        try {
            setLoading(true);
            const data = await getAds(params);
            setAds(data);
        } catch (error) {
            console.error('Error fetching ads:', error);
            if (error.response) {
                console.error('Server response:', error.response.data);
                console.error('Server status:', error.response.status);
                console.error('Server headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Request setup error:', error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const params = queryString.parse(location.search);
        dispatch(setFilters({
            categoryId: params.categoryId || '',
            subcategoryId: params.subcategoryId || '',
            subSubcategoryId: params.subSubcategoryId || '',
            priceFrom: params.priceFrom || '',
            priceTo: params.priceTo || '',
            keyword: params.keyword || '',
            regionId: params.regionId || ''
        }));
    }, [location.search, dispatch]);

    useEffect(() => {
        const params = {
            category_id: formData.subSubcategoryId || formData.subcategoryId || formData.categoryId,
            price_from: formData.priceFrom,
            price_to: formData.priceTo,
            keyword: formData.keyword,
            region_id: formData.regionId
        };
        fetchAds(params);
    }, [formData]);

    const handleFormDataChange = (newFormData) => {
        dispatch(setFilters(newFormData));

        const nonEmptyParams = Object.keys(newFormData)
            .filter(key => newFormData[key])
            .reduce((obj, key) => {
                obj[key] = newFormData[key];
                return obj;
            }, {});

        const queryStringParams = queryString.stringify(nonEmptyParams);
        navigate(`/?${queryStringParams}`);
    };

    const clearFormData = () => {
        const clearedFormData = {
            categoryId: formData.categoryId,
            subcategoryId: '',
            subSubcategoryId: '',
            priceFrom: '',
            priceTo: '',
            keyword: '',
            regionId: ''
        };
        handleFormDataChange(clearedFormData);
        dispatch(clearFilters())
    };

    return (
        <Container>
            <FindProduct theme={theme} />
            <CategotyList theme={theme} formData={formData} />
            {/*{formData.categoryId && (
                        <>
                            <SubcategotyList theme={theme} formData={formData} setFormData={handleFormDataChange} />
                            <Filter theme={theme} formData={formData} setFormData={handleFormDataChange} />
                            <ButtonComponent theme={theme} clearFormData={clearFormData} />
                        </>
                    )} */}

            <Recommendations />
        </Container>
        //     {/* {formData.categoryId && (
        //     <Container
        //         sx={{
        //             justifyContent: 'center',
        //             display: "flex",
        //             alignItems: "center",
        //             flexDirection: "column",
        //             m: "20px auto",
        //             paddingLeft: "0px!important",
        //             paddingRight: "0px!important",
        //             borderRadius: "5px",
        //             boxShadow: "0 2px 3px 0 rgba(0,0,0,0.25)"
        //         }}
        //     >
        //         <MapComponent data={ads} />
        //     </Container>
        // )} */}
    );
}

export default HomePage;
