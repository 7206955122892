import React, { useState } from 'react';
import { TextField, Button, Box, Grid, Typography } from '@mui/material';
import { passwordChange } from '../../../api/contactChange';

const ChangePassword = () => {
    const [formData, setFormData] = useState({
        oldPassword: '',
        firstPassword: '',
        secondPassword: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {
        if (formData.firstPassword !== formData.secondPassword) {
            setErrorMessage("Пароли не совпадают");
            setSuccessMessage('');
            return;
        }

        try {
            const body = {
                old_password: formData.oldPassword,
                new_password: formData.firstPassword
            }
            const response = await passwordChange(body)

            if (response.status === 200) {
                setSuccessMessage(response.data.message);
                setErrorMessage('');
                setFormData({
                    oldPassword: '',
                    firstPassword: '',
                    secondPassword: ''
                });
            }
        } catch (error) {
            setErrorMessage(error.response.data.error || 'Ошибка при обновлении пароля');
            setSuccessMessage('');
        }
    };

    return (
        <Box sx={{
            m: "20px auto",
            p: "30px",
            // borderRadius: "5px",
            // boxShadow: "0 2px 5px 0 rgba(0,0,0,0.15)",
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Старый пароль"
                                name="oldPassword"
                                type="password"
                                value={formData.oldPassword}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Новый пароль"
                                name="firstPassword"
                                type="password"
                                value={formData.firstPassword}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Подтвердить пароль"
                                name="secondPassword"
                                type="password"
                                value={formData.secondPassword}
                                onChange={handleChange}
                            />
                        </Grid>
                        {errorMessage && (
                            <Grid item xs={12}>
                                <Typography color="error">{errorMessage}</Typography>
                            </Grid>
                        )}
                        {successMessage && (
                            <Grid item xs={12}>
                                <Typography color="primary">{successMessage}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                                Изменить
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChangePassword;
