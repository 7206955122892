import axiosInstance from './axios';

export const contactChange = async (body) => {
  const response = await axiosInstance.put('/users', body);
  return response;
};

export const passwordChange = async (body) => {
  return await axiosInstance.put('/users/password', body)
}

export const deactivateAcc = async () => {
  return await axiosInstance.put('/users/deactivate')
}