import { combineReducers } from "redux";
import postAdReducer from "./slices/isClickPostAdSlice";
import userReducer from './slices/userSlice';
import chatReducer from "./slices/chatReducer";
import filterReducer from './slices/filterSlice';

const rootReducer = combineReducers({
  postAd: postAdReducer,
  user: userReducer,
  chat: chatReducer,
  filter: filterReducer
});

export default rootReducer;
