import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, Box, Grid, Avatar, MenuItem, Select, FormControl } from '@mui/material';
import { selectUserInfo, setUserInfo } from '../../../config/slices/userSlice';
import { useTranslation } from 'react-i18next';
import { getRegions } from '../../../api/regions';
import { contactChange } from '../../../api/contactChange';

const ContactDetails = () => {
    const { i18n } = useTranslation();
    const userInfo = useSelector(selectUserInfo);
    const dispatch = useDispatch();
    const language = i18n.language;
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    // Заполнение начальных значений формы при загрузке данных пользователя
    useEffect(() => {
        if (userInfo) {
            setFirstName(userInfo.first_name || '');
            setLastName(userInfo.last_name || '');
            setPhoneNumber(userInfo.phone_number || '');
            setEmail(userInfo.email || '');
            if (userInfo.region_id !== 0) {
                setSelectedRegion(userInfo.region_id);
            }
        }
    }, [userInfo]);

    // Загрузка регионов при изменении языка
    useEffect(() => {
        const fetchInitialRegions = async () => {
            await fetchRegions(0);
        };
        fetchInitialRegions();
    }, [language]);

    // Загрузка списка регионов
    const fetchRegions = async (parentId) => {
        try {
            const data = await getRegions(parentId, language);
            if (parentId === 0) {
                setRegions(data);
            }
        } catch (error) {
            console.error('Error fetching regions:', error);
        }
    };

    // Обработчик изменения выбранного региона
    const handleRegionChange = (event) => {
        const regionId = event.target.value;
        setSelectedRegion(regionId);
        dispatch(setUserInfo({
            ...userInfo, // Передаем все текущие данные userInfo
            region_id: regionId
        }));
    };

    // Обработчик сохранения данных пользователя
    const handleSave = async () => {
        try {
            if (selectedRegion === "") { return alert("region") }
            const updatedUserInfo = {
                ...userInfo,
                phone_number: phoneNumber,
                first_name: firstName,
                last_name: lastName,
                email: email,
                region_id: selectedRegion
            };
            dispatch(setUserInfo(updatedUserInfo));

            const response = await contactChange(updatedUserInfo);

            if (response.status === 200) {
                alert(response.data.message)
            } else {
                throw new Error('Failed to update user data');
            }

        } catch (error) {
            console.error('Error updating user data:', error);
        }
    };

    return (
        <Box sx={{
            m: "20px auto",
            p: "30px",
            // borderRadius: "5px",
            // boxShadow: "0 2px 5px 0 rgba(0,0,0,0.15)",
        }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Avatar alt="User Avatar" src="https://example.com/avatar.jpg" sx={{ width: 128, height: 128 }} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Имя"
                                name="first_name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Фамилия"
                                name="last_name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Номер телефона"
                                name="phone_number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Select
                                    labelId="region-select-label"
                                    id="region-select"
                                    value={selectedRegion}
                                    onChange={handleRegionChange}
                                    style={{ maxHeight: '400px' }}
                                >
                                    <MenuItem value="">----</MenuItem>
                                    {regions?.map((region) => (
                                        <MenuItem key={region.id} value={region.id}>
                                            {region.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
                                СОХРАНИТЬ
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContactDetails;
