import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const TermsOfService = () => {
    return (
        <Container maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Условия использования
                </Typography>
                <Typography variant="body1" paragraph>
                    Добро пожаловать на нашу платформу по размещению объявлений. Используя наш сайт, вы соглашаетесь с нижеприведенными условиями использования.
                </Typography>
                
                <Typography variant="h6" gutterBottom>
                    1. Регистрация и учетная запись
                </Typography>
                <Typography variant="body1" paragraph>
                    При регистрации на сайте вы обязаны предоставить достоверную информацию, включая ваше имя и номер мобильного телефона. Эти данные будут использоваться для связи с вами по поводу ваших объявлений и взаимодействия с другими пользователями.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    2. Подача объявлений
                </Typography>
                <Typography variant="body1" paragraph>
                    Подача объявлений на нашем сайте бесплатна. Вы можете размещать только те товары, которые у вас есть в наличии, и только в соответствующих категориях. Нарушение этих правил может привести к удалению объявления или блокировке учетной записи.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    3. Общение между пользователями
                </Typography>
                <Typography variant="body1" paragraph>
                    На платформе разрешена переписка между пользователями по поводу объявлений. Пользователи обязаны соблюдать правила приличия, избегать оскорблений, спама и обсуждения запрещенных товаров или услуг в Казахстане. Нарушение этих правил может привести к блокировке учетной записи.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    4. Модерация контента
                </Typography>
                <Typography variant="body1" paragraph>
                    Мы оставляем за собой право модерации и удаления любого контента, который нарушает наши правила. Это включает, но не ограничивается, ложными объявлениями, некорректной категоризацией товаров, и нарушениями законодательства Республики Казахстан.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    5. Жалобы и разрешение споров
                </Typography>
                <Typography variant="body1" paragraph>
                    В случае возникновения споров или жалоб на пользователя, вы можете связаться с нами по электронной почте kz.maltap@gmail.com. Мы рассмотрим ваше обращение и примем необходимые меры.
                </Typography>

                <Typography variant="body1" paragraph>
                    Спасибо, что выбрали нашу платформу. Мы стремимся обеспечить безопасное и удобное использование нашего сайта для всех пользователей.
                </Typography>
            </Box>
        </Container>
    );
};

export default TermsOfService;
